import { Box, Typography } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import Entry from "./Entry";
import { SectionInterface } from "../../interfaces/interfaces";
import AttendanceTardyData from "./AttendanceTardyData";


type Props = {
  classSections: SectionInterface[] | null;
  studentSections: SectionInterface[] | null;
  showImages: boolean;
};

function PrintTable({ classSections, studentSections, showImages }: Props) {
    
  return (
    <>
      <Header />
      <Footer />

      <table>
        <thead>
          <tr>
            <td>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <Typography variant="h2" sx={{ textAlign: "center" }}>
                Class Overview
              </Typography>
              {classSections &&
                classSections.map((section) => (
                  <Box key={section.id}>
                    <Entry section={section} showImages={showImages} />
                  </Box>
                ))}
              <Typography variant="h2" sx={{ mt: 2, textAlign: "center" }}>
                About Your Child
              </Typography>
              {studentSections &&
                studentSections.map((section) => (
                  <Box key={section.id}>
                    <Entry section={section} showImages={showImages} />
                  </Box>
                ))}
            </td>
          </tr>
          <tr>
            <td>
              <AttendanceTardyData />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default PrintTable;
