import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Paper } from "@mui/material";
import {
  selectedStudentIdAtom,
  tardyLogsAtom,
  attendanceRecordsAtom,
  studentsAtom,
} from "../../recoil/atoms";
import StudentDataContainer from "../StudentData/StudentDataContainer";

function AttendanceTardyData() {
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const students = useRecoilValue(studentsAtom);
  const tardyLogs = useRecoilValue(tardyLogsAtom);
  const attendanceRecords = useRecoilValue(attendanceRecordsAtom);
  const [numberOfTardies, setNumberOfTardies] = useState<number>(0);
  const [numberOfAbsences, setNumberOfAbsences] = useState<number>(0);

  useEffect(() => {
    if (!selectedStudentId || !tardyLogs) return;
    const userTardyLogs = tardyLogs.filter((log) => log.studentId === selectedStudentId);
    const startingDate = new Date("07/01/2022").getTime();
    let tardiesCount = 0;
    userTardyLogs.forEach((log) => {
      if (log.millis > startingDate) {
        tardiesCount++;
      }
    });
    setNumberOfTardies(tardiesCount);
  }, [selectedStudentId, tardyLogs]);

  useEffect(() => {
    if (!attendanceRecords || !students) return;
    const currentStudent = students.find((student) => selectedStudentId === student.id);
    if (!currentStudent) return;
    const userAttendanceRecords = attendanceRecords.filter(
      (record) => record.SID === currentStudent.SID
    );
    const startingDate = new Date("07/01/2022").getTime();
    let attendanceCount = 0;
    userAttendanceRecords.forEach((record) => {
      if (new Date(record.absenceDate).getTime() > startingDate) {
        attendanceCount++;
      }
    });
    setNumberOfAbsences(attendanceCount);
  }, [attendanceRecords, students, selectedStudentId]);
  return (
    <>
      {Boolean(selectedStudentId) && (
        <Paper sx={{ padding: 2, mt: 2, mb: 2 }}>
          <StudentDataContainer />
        </Paper>
      )}
    </>
  );
}

export default AttendanceTardyData;
