import { useState, useEffect } from "react";
import { Alert, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { attendanceRecordsAtom, selectedStudentIdAtom, studentsAtom } from "../../recoil/atoms";

function AttendanceData() {
  const students = useRecoilValue(studentsAtom);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const attendanceRecords = useRecoilValue(attendanceRecordsAtom);
  const [numberOfAbsences, setNumberOfAbsences] = useState<number>(0);

  useEffect(() => {
    if (!attendanceRecords || !students) return;
    const currentStudent = students.find((student) => selectedStudentId === student.id);
    if (!currentStudent) return;
    const userAttendanceRecords = attendanceRecords.filter(
      (record) => record.SID === currentStudent.SID
    );
    const startingDate = new Date("07/01/2023").getTime();
    let attendanceCount = 0;
    userAttendanceRecords.forEach((record) => {
      if (new Date(record.absenceDate).getTime() > startingDate) {
        attendanceCount++;
      }
    });
    setNumberOfAbsences(attendanceCount);
  }, [attendanceRecords, students, selectedStudentId]);

  return (
    <>
      {attendanceRecords && (
        <>
          <Typography sx={{ mt: 2 }} variant="body2">
            <b>Number of Absences: </b> {numberOfAbsences}
          </Typography>
          {numberOfAbsences > 18 && (
            <Alert severity="error">
              Warning, your child is at risk of being held back due to excessive absences.
            </Alert>
          )}
        </>
      )}
    </>
  );
}

export default AttendanceData;
