import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { periodsAtom, selectedPeriodIdAtom, entriesResetAtom } from "../../recoil/atoms";
import React, { useEffect } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function NarrativePeriodSelect() {
  const periods = useRecoilValue(periodsAtom);
  const setEntriesReset = useSetRecoilState(entriesResetAtom);
  const [selectedPeriodId, setSelectedPeriodId] = useRecoilState(selectedPeriodIdAtom);
  const defaultActivePeriod = periods.find((period) => period.isActive);
  const { width } = useWindowDimensions();
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedPeriodId(event.target.value);
    setEntriesReset((pV) => !pV);
  };

  useEffect(() => {
    if (!defaultActivePeriod || !selectedPeriodId) return;
    if (defaultActivePeriod && selectedPeriodId === "None") {
      setSelectedPeriodId(defaultActivePeriod.id);
    }
  }, [defaultActivePeriod, selectedPeriodId]);

  return (
    <>
      <Select
        value={selectedPeriodId}
        onChange={handleChange}
        className="menuSelect"
        sx={{
          fontWeight: 800,
          borderColor: "#fff",
          fontSize: ".8em",
          maxWidth: width < 900 ? "200px" : "100%",
          color: width < 900 ? "#000" : "#fff",
        }}
      >
        <MenuItem value="none">None</MenuItem>
        {defaultActivePeriod && (
          <MenuItem value={defaultActivePeriod.id}>{defaultActivePeriod.label}</MenuItem>
        )}
        {periods &&
          periods.map(
            (period) =>
              !period.isActive && (
                <MenuItem key={period.id} value={period.id}>
                  {period.label}
                </MenuItem>
              )
          )}
      </Select>
    </>
  );
}
