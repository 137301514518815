import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
    interface TypographyVariants {
      cardTitle: React.CSSProperties;
    }
  
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        cardTitle?: React.CSSProperties;
    }
    interface PaletteOptions {
      tertiary: PaletteOptions['primary'];
      white: PaletteOptions['primary'];
    }
    interface Theme {
      status: {
        white: React.CSSProperties['color'];
      };
    }
    interface PaletteColor {
      white?: string;
    }
    interface SimplePaletteColorOptions {
      white?: string;
    }
  }
  
  // Update the Typography's variant prop options
  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        cardTitle: true;
    }
  }
  

export const theme = createTheme({
  palette: {
    primary: {
      main: "#26C6F5",
      contrastText: "#fff",
      white: "#fff"
    },
    secondary: {
      main: "#B930E6",
    },
    tertiary: {
      main: "#F6E649",
      contrastText: "#555",
    },
    white: {
      main: "#fff",
      contrastText: "#333",
    },
    info: {
      main: "#ccc",
      contrastText: "#666",
    }
  },
  typography: {
    fontFamily: "Urbanist, Arial",
    h2: {
      fontSize: 46,
      color: "#26C6F5",
    },
    h3: {
      fontSize: 36,
      color: "#333",
    },
    h4: {
      color: "#26C6F5",
      fontSize: 30,
    },
    h5: {
      fontSize: 26,
      fontWeight: 800,
    },
    h6: {
      fontSize: 18,
      fontWeight: 800,
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
    },
    body2: {
      fontSize: 18,
      fontWeight: 300,
      color: "#333",
    },
  }
});
