import { useContext } from "react";
import { Container, Backdrop, CircularProgress } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import ClassContainer from "../components/SectionTypes/ClassContainer";
import { useRecoilValue } from "recoil";
import { selectedPeriodIdAtom, studentsAtom } from "../recoil/atoms";
import { AuthContext } from "../providers/AuthProvider";

const ClassPage = () => {
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const students = useRecoilValue(studentsAtom);
  const { currentAuthUser } = useContext(AuthContext);
  const loading = Boolean(!selectedPeriodId || !currentAuthUser || !students);

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
        {loading ? (
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <ClassContainer />
        )}
      </Container>
    </>
  );
};

export default ClassPage;
