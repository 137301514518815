import { FC, useContext } from "react";
import { Navigate } from "react-router-dom";
import { PERMISSION } from "./libraries/objects";
import { AuthContext } from "./providers/AuthProvider";
import { loggedInStaffAtom } from "./recoil/atoms";
import { useRecoilValue } from "recoil";

interface CustomRouteProps {
  children: React.ReactNode;
  permission: PERMISSION | null;
}

const CustomRoute: FC<CustomRouteProps> = ({ children, permission }) => {
  const { loading, currentAuthUser } = useContext(AuthContext);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  return (
    <>
      {currentAuthUser && !loading ? children : <Navigate to="/class" />}
      {!currentAuthUser && !loading && <Navigate to="/login" />}
    </>
  );
};

export default CustomRoute;
