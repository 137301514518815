import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  selectedStudentIdAtom,
  entriesResetAtom,
  availableStudentsAtom,
} from "../../recoil/atoms";
import { StudentInterface } from "../../interfaces/interfaces";

function SelectStudent() {
  const [selectedStudentId, setSelectedStudentId] = useRecoilState(selectedStudentIdAtom);
  const setEntriesReset = useSetRecoilState(entriesResetAtom);
  const students = useRecoilValue(availableStudentsAtom);
  const createStudentLabel = (student: StudentInterface) => {
    return `${student.childFirstName} ${student.childLastName}`;
  };

  const handleStudentChange = (event: SelectChangeEvent) => {
    setSelectedStudentId(event.target.value);
    setEntriesReset((pV) => !pV);
  };
  return (
    <div>
      <InputLabel id="student-select-label">Select Student</InputLabel>
      <Select
        labelId="student-select-label"
        value={selectedStudentId || "none"}
        label="Select Student"
        onChange={handleStudentChange}
      >
        <MenuItem value="none">None Selected</MenuItem>
        {students &&
          students.map((student) => (
            <MenuItem key={student.id} value={student.id}>
              {createStudentLabel(student)}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}

export default SelectStudent;
