import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  homeroomsAtom,
  studentsAtom,
  staffFromAPIAtom,
  selectedHomeroomIdAtom,
  tardyLogsAtom,
  gradesAtom,
  earlyPickUpLogsAtom,
} from "../recoil/atoms";
import {
  HomeroomInterface,
  StudentInterface,
  StaffFromAPIInterface,
  TardyLogInterface,
} from "../interfaces/interfaces";
import { parseOverviewResponse, parseTardyLogResponse } from "../libraries/parsers";
import { overviewDataAtom } from "../recoil/academicAtoms";

type FIREBASE_ID = string;

const useBootstrapAPIEffect = () => {
  const [loading, setLoading] = useState(false);
  const [homerooms, setHomerooms] = useRecoilState<HomeroomInterface[]>(homeroomsAtom);
  const setGrades = useSetRecoilState(gradesAtom);
  const [students, setStudents] = useRecoilState<StudentInterface[]>(studentsAtom);
  const [staffFromAPI, setStaffFromAPI] = useRecoilState<StaffFromAPIInterface[]>(staffFromAPIAtom);
  const [tardyLogs, setTardyLogs] = useRecoilState(tardyLogsAtom);
  const [earlyPickUpLogs, setEarlyPickUpLogs] = useRecoilState(earlyPickUpLogsAtom);
  const [overviewData, setOverviewData] = useRecoilState(overviewDataAtom);
  const selectedHomeroomId = useRecoilValue<FIREBASE_ID>(selectedHomeroomIdAtom);

  useEffect(() => {
    setLoading(true);
    if (
      homerooms.length > 0 &&
      students.length > 0 &&
      staffFromAPI.length > 0 &&
      staffFromAPI.length > 0
    ) {
      setLoading(false);
    }
  }, [homerooms, students, staffFromAPI, tardyLogs]);

  useEffect(() => {
    const getHomerooms = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/homerooms",
        {
          headers: headers,
        }
      );
      const json = await response.json();
      setHomerooms(json);
    };
    getHomerooms();
  }, [setHomerooms, selectedHomeroomId]);

  useEffect(() => {
    const getGrades = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/grades",
        {
          headers: headers,
        }
      );
      const json = await response.json();
      setGrades(json);
    };
    getGrades();
  }, [setGrades]);

  useEffect(() => {
    const getStudents = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/students",
        {
          headers: headers,
        }
      );
      const json = await response.json();
      setStudents(json);
    };
    getStudents();
  }, [setStudents]);

  useEffect(() => {
    if (homerooms.length === 0) return;
    const getStaff = async () => {
      if (homerooms.length === 0) return;
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-core-api.cloudfunctions.net/app/api/staff",
        {
          headers: headers,
        }
      );
      const jsonStaff = await response.json();
      const homeroomsIds = homerooms.map((homeroom) => homeroom.id);
      setStaffFromAPI([...jsonStaff, { homerooms: homeroomsIds }]);
    };
    getStaff();
  }, [setStaffFromAPI, homerooms]);

  useEffect(() => {
    const getTardyLogs = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-tardy-system.cloudfunctions.net/app/api/tardyLogs",
        {
          headers: headers,
        }
      );
      const json = await response.json();
      setTardyLogs(parseTardyLogResponse(json));
    };
    getTardyLogs();
  }, [setTardyLogs]);

  useEffect(() => {
    const getEarlyPickUpLogs = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-tardy-system.cloudfunctions.net/app/api/earlyPickUp",
        {
          headers: headers,
        }
      );
      const json = await response.json();
      setEarlyPickUpLogs(parseTardyLogResponse(json));
    };
    getEarlyPickUpLogs();
  }, [setEarlyPickUpLogs]);

  useEffect(() => {
    const getOverviews = async () => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
        authorization: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
      };
      const response = await fetch(
        "https://us-central1-tgp-data-analysis.cloudfunctions.net/app/api/overviews",
        {
          headers: headers,
        }
      );
      const json = await response.json();
      setOverviewData(parseOverviewResponse(json));
    };
    getOverviews();
  }, [setOverviewData]);

  return loading;
};

export default useBootstrapAPIEffect;
