import { Container, Backdrop, CircularProgress } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import StaffContainer from "../components/Staff/StaffContainer";
import { useRecoilValue } from "recoil";
import {
  periodsAtom,
  selectedPeriodIdAtom,
  selectedHomeroomIdAtom,
  loggedInStaffAtom,
  availableHomeroomsAtom,
  staffAtom
} from "../recoil/atoms";

const StaffPage = () => {
  const periods = useRecoilValue(periodsAtom);
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const availableHomerooms = useRecoilValue(availableHomeroomsAtom);
  const navbarLoading = Boolean(
    !periods || !selectedPeriodId || !selectedHomeroomId || !loggedInStaff || !availableHomerooms
  );
  const loading = Boolean(!staffAtom)
  return (
    <>
      {!navbarLoading && <Navbar />}
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8 }}>
        {(navbarLoading || loading) ? (
          <Backdrop open={(navbarLoading || loading)}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <StaffContainer />
        )}
      </Container>
    </>
  );
};

export default StaffPage;
