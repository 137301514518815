import NewSection from "./NewSection";
import SectionsList from "./SectionsList";

function SectionsContainer() {
  return (
    <>
      <NewSection />
      <SectionsList />
    </>
  );
}

export default SectionsContainer;
