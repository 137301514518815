import { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import useBootstrapEffect from "./hooks/useBootstrapEffect";
import useBootstrapAPIEffect from "./hooks/useBootstrapAPIEffect";
import { routes } from "./Routes";
import { AuthContext } from "./providers/AuthProvider";

function AppBootstrap() {
  const { loading } = useContext(AuthContext);
  const bootstrapAPILoading = useBootstrapAPIEffect();
  useBootstrapEffect();
  return (
    <Router>
      {bootstrapAPILoading || loading ? (
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <main className="root">{routes}</main>
      )}
    </Router>
  );
}

export default AppBootstrap;
