import { atom, selector } from "recoil";
import {
  StaffInterface,
  PeriodInterface,
  HomeroomInterface,
  StudentInterface,
  StaffFromAPIInterface,
  SectionInterface,
  EntryInterface,
  ObjectiveInterface,
  TardyLogInterface,
  AttendanceRecordInterface,
  RosterOverrideInterface,
  MClassRecordInterface,
} from "../interfaces/interfaces";
import {
  availableStudentsGetter,
  studentSectionsGetter,
  classSectionsGetter,
  availableHomeroomsGetter,
  studentsObjectGetter,
  mClassWithSixDigitIds,
} from "./selectors";
import { GradeRecord, PromotionIndicator, PromotionIndicatorRecord } from "../types/types";

type FIREBASE_ID = string;

//****** PERIOD ATOMS ******//

export const periodsAtom = atom<PeriodInterface[]>({
  key: "periods",
  default: [],
});

export const periodsResetAtom = atom({
  key: "periodsReset",
  default: false,
});

//TODO - update the default to be selected from settings once Pankti's PR is submitted
export const selectedPeriodIdAtom = atom<FIREBASE_ID>({
  key: "selectedPeriodId",
  default: "None",
});

//****** STAFF ATOMS ******//

export const staffAtom = atom<StaffInterface[]>({
  key: "staff",
  default: [],
});

export const staffFromAPIAtom = atom<StaffFromAPIInterface[]>({
  key: "staffFromAPI",
  default: [],
});

export const loggedInStaffAtom = atom<StaffInterface | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

//****** HOMEROOM ATOMS ******//

export const homeroomsAtom = atom<HomeroomInterface[]>({
  key: "homerooms",
  default: [],
});

export const availableHomeroomsAtom = selector<HomeroomInterface[] | null>({
  key: "availableHomerooms",
  get: availableHomeroomsGetter,
});

export const selectedHomeroomIdAtom = atom<FIREBASE_ID>({
  key: "selectedHomeroomId",
  default: "",
});

//****** GRADE ATOMS ******//

export const gradesAtom = atom<GradeRecord[]>({
  key: "grades",
  default: [],
});

//****** ENTRIES ATOMS ******//

export const entriesAtom = atom<EntryInterface[] | null>({
  key: "entries",
  default: null,
});

export const entriesResetAtom = atom({
  key: "entriesReset",
  default: false,
});

//****** SECTIONS ATOMS ******//

export const sectionsAtom = atom<SectionInterface[] | null>({
  key: "sections",
  default: null,
});

export const classSectionsAtom = selector<SectionInterface[] | null>({
  key: "classSections",
  get: classSectionsGetter,
});

export const studentSectionsAtom = selector<SectionInterface[] | null>({
  key: "studentSections",
  get: studentSectionsGetter,
});

export const sectionsResetAtom = atom<boolean>({
  key: "sectionsReset",
  default: false,
});

//****** OBJECTIVES ATOMS ******//

export const objectivesAtom = atom<ObjectiveInterface[]>({
  key: "objectives",
  default: [],
});

export const objectivesResetAtom = atom<boolean>({
  key: "objectivesReset",
  default: false,
});

export const activeNewObjectiveIdAtom = atom<string>({
  key: "activeNewObjectiveId",
  default: "",
});

//****** STUDENT ATOMS ******//

export const studentsAtom = atom<StudentInterface[]>({
  key: "students",
  default: [],
});

export const selectedStudentIdAtom = atom<FIREBASE_ID>({
  key: "selectedStudentId",
  default: "",
});

export const availableStudentsAtom = selector({
  key: "availableStudents",
  get: availableStudentsGetter,
});

export const studentsObjAtom = selector({
  key: "studentsObj",
  get: studentsObjectGetter,
});

export const promotionIndicatorAtom = atom<PromotionIndicatorRecord[]>({
  key: "promotionIndicator",
  default: [],
});

export const promotionIndicatorResetAtom = atom<boolean>({
  key: "promotionIndicatorReset",
  default: false,
});

//****** Tardy ATOMS ******//

export const tardyLogsAtom = atom<TardyLogInterface[]>({
  key: "tardyLogs",
  default: [],
});

export const earlyPickUpLogsAtom = atom<TardyLogInterface[]>({
  key: "earlyPickUpLogs",
  default: [],
});

//****** Attendance ATOMS ******//

export const attendanceRecordsAtom = atom<AttendanceRecordInterface[] | null>({
  key: "attendanceRecords",
  default: [],
});

//****** mClass ATOMS ******//

export const rawMClassRecordsAtom = atom<MClassRecordInterface[]>({
  key: "rawMClassRecords",
  default: [],
});

export const mClassRecordsAtom = selector({
  key: "mClassRecords",
  get: mClassWithSixDigitIds,
});

//****** Roster Overrides ATOMS ******//

export const rosterOverridesAtom = atom<RosterOverrideInterface[] | null>({
  key: "rosterOverrides",
  default: [],
});

export const rosterOverridesResetAtom = atom<boolean>({
  key: "rosterOverridesReset",
  default: false,
});

// const activePeriod = periods.find(period => period.makeActive);
// useEffect(() => {
//   if (activePeriod) {
//     setSelectedPeriodId(activePeriod.id);
//   }
// }, [activePeriod]);

// {defaultActivePeriod && (
//   <MenuItem value={defaultActivePeriod.id}>{defaultActivePeriod.label}</MenuItem>
// )}
// {periods &&
//   periods.map((period) => (
//     !period.makeActive  && (
//       <MenuItem key={period.id} value={period.id}>
//         {period.label}
//       </MenuItem>
//     )
//   ))}
// </Select>
// </>
// );
// }
