import { useState, SyntheticEvent } from "react";
import {
  Grid,
  TextField,
  Button,
  Paper,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import useAddDoc from "../../hooks/useAddDoc";
import { GradeInterface } from "../../interfaces/interfaces";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { gradesAtom, objectivesResetAtom } from "../../recoil/atoms";

type FIREBASE_ID = string;

type FormState = EventTarget & {
  name: string;
  value: string;
  checked: boolean;
};

type Props = {
  sectionId: FIREBASE_ID;
};

type ObjectivesForm = {
  label: string;
  order: number;
  gradeIds: string[];
  sectionId: string;
};

//This whole component is weird because I couldn't figure out how to get the grades to dynamically set on change for the switches due to a type script error saying Element implicitly has an 'any' type because expression of type 'string' can't be used to index type #

function NewObjective({ sectionId }: Props) {
  const blankForm = {
    label: "",
    order: 0,
    sectionId,
    gradeIds: [],
  };
  const [objectiveForm, setObjectiveForm] = useState<ObjectivesForm>(blankForm);
  const { sendRequest: addDoc } = useAddDoc();
  const setObjectivesReset = useSetRecoilState(objectivesResetAtom);
  const grades = useRecoilValue(gradesAtom);

  const handleTextChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setObjectiveForm((pV) => ({ ...pV, [formState.name]: formState.value }));
  };

  const handleNumberChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setObjectiveForm((pV) => ({ ...pV, [formState.name]: Number(formState.value) }));
  };

  const handleGradeChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    const gradeIds = [...objectiveForm.gradeIds];
    if (gradeIds.includes(formState.name)) {
      gradeIds.splice(gradeIds.indexOf(formState.name), 1);
    } else {
      gradeIds.push(formState.name);
    }
    setObjectiveForm((pV) => ({ ...pV, gradeIds: gradeIds }));
  };

  const handleSave = async () => {
    await addDoc({ col: "objectives", data: objectiveForm });
    setObjectivesReset((pV) => !pV);
    setObjectiveForm(blankForm);
  };

  return (
    <Paper sx={{ padding: 2, mt: 2 }}>
      <Typography variant="h6">Create New Objective</Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item sm={8}>
          <TextField
            label="Objective Name"
            fullWidth
            value={objectiveForm.label}
            onChange={handleTextChange}
            name="label"
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label="Order"
            fullWidth
            value={String(objectiveForm.order)}
            onChange={handleNumberChange}
            name="order"
            type="number"
          />
        </Grid>
        <Grid item sm={2}>
          <Button variant="outlined" fullWidth sx={{ padding: 2 }} onClick={handleSave}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {grades.map((grade) => (
            <FormControlLabel
              key={grade.id}
              control={
                <Switch
                  checked={objectiveForm.gradeIds.includes(grade.id)}
                  onChange={handleGradeChange}
                  name={grade.id}
                />
              }
              label={grade.name}
            />
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default NewObjective;
