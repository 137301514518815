import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import { standardsAtom } from "../../recoil/standardsAtoms";
import { StudentCourseScore, StudentStandardScore } from "../../types/types";
import {
  studentCourseScoresAtom,
  studentStandardScoresAtom,
} from "../../recoil/studentScoresAtoms";
import UploadTestResultsForm from "./UploadTestResultsForm";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

enum Column {
  SID = "SID",
  SCORE = "Score",
  LEVEL = "Level",
  GRADE = "Grade",
  SUBJECT = "Subject",
  DATE_TAKEN = "Date Taken",
}

type DynamicObject = {
  [key: string]: string;
};

const UploadTestResultsContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const standards = useRecoilValue(standardsAtom);
  const studentStandardScores = useRecoilValue(studentStandardScoresAtom);
  const studentCourseScores = useRecoilValue(studentCourseScoresAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);

  const searchCourseScoreRecords = (newRecord: StudentCourseScore) => {
    if (!standards) return;
    const matchingRecords = studentCourseScores.filter(
      (existingRecord) =>
        existingRecord.SID === newRecord.SID &&
        existingRecord.grade === newRecord.grade &&
        existingRecord.subject === newRecord.subject
    );
    return matchingRecords.length > 0 ? true : false;
  };

  const searchStandardScoreRecords = (newRecord: StudentStandardScore) => {
    if (!standards) return;
    const matchingRecords = studentStandardScores.filter(
      (existingRecord) =>
        existingRecord.SID === newRecord.SID &&
        existingRecord.standardKey === newRecord.standardKey &&
        existingRecord.dateTaken === newRecord.dateTaken
    );
    return matchingRecords.length > 0 ? true : false;
  };

  const fromWebsite = async (value: DynamicObject) => {
    const dateTaken = new Date(value[Column.DATE_TAKEN]).toISOString();

    const courseRecord: StudentCourseScore = {
      grade: value[Column.GRADE] || "",
      subject: value[Column.SUBJECT] || "",
      SID: value[Column.SID] || "",
      score: value[Column.SCORE] || "",
      level: value[Column.LEVEL] || "",
      dateTaken: dateTaken,
    };

    const courseScoresFound = searchCourseScoreRecords(courseRecord);
    let resultId: string | null = "";
    if (!courseScoresFound) {
      resultId = await addDoc({ col: "studentCourseScores", data: courseRecord });
    } else {
    }

    const standardsToSearch = standards.filter(
      (standard) =>
        standard.grade === value[Column.GRADE] && standard.subject === value[Column.SUBJECT]
    );
    standardsToSearch.forEach(async (standard) => {
      if (value[standard.key]) {
        const standardRecord = {
          SID: value[Column.SID] || "",
          percentage: value[standard.key] || "",
          standardKey: standard.key,
          dateTaken: dateTaken,
        };
        const standardScoresFound = searchStandardScoreRecords(standardRecord);
        if (!standardScoresFound) {
          await addDoc({ col: "studentStandardScores", data: standardRecord });
        }
      }
    });
    return resultId ? resultId : "";
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<DynamicObject>(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            const resultId = fromWebsite(value);
            promises.push(resultId);
          });
        },
        header: true,
      });
      await Promise.all(promises);
      setLoading(false);
    }
  };

  return (
    <UploadTestResultsForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
    />
  );
};

export default UploadTestResultsContainer;
