import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { selectedStudentIdAtom, tardyLogsAtom } from "../../recoil/atoms";

function TardyData() {
  const tardyLogs = useRecoilValue(tardyLogsAtom);
  const [numberOfTardies, setNumberOfTardies] = useState<number>(0);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);

  useEffect(() => {
    if (!selectedStudentId || !tardyLogs) return;
    const userTardyLogs = tardyLogs.filter((log) => log.studentId === selectedStudentId);
    const startingDate = new Date("07/01/2023").getTime();
    let tardiesCount = 0;
    userTardyLogs.forEach((log) => {
      if (log.millis > startingDate) {
        tardiesCount++;
      }
    });
    setNumberOfTardies(tardiesCount);
  }, [selectedStudentId, tardyLogs]);

  return (
    <Typography sx={{ mt: 2 }} variant="body2">
      <b>Number of Tardies: </b> {numberOfTardies}
    </Typography>
  );
}

export default TardyData;
