import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { periodsAtom, periodsResetAtom } from "../../recoil/atoms";
import PeriodDialog from "./PeriodDialog";
import PeriodCard from "./PeriodCard";
import NewPeriodButton from "./NewPeriodButton";
import useAddDoc from "../../hooks/useAddDoc";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { BLANK_PERIOD_FORM } from "../../libraries/objects";
import { PeriodInterface } from "../../interfaces/interfaces";

export default function PeriodsContainer() {
  const periods = useRecoilValue(periodsAtom);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [periodForm, setPeriodForm] = useState<PeriodInterface>(
    BLANK_PERIOD_FORM
  );
  const setPeriodsReset = useSetRecoilState(periodsResetAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: updateDoc } = useUpdateDoc();

  const saveForm = () => {
    const data = { ...periodForm };
    if (!periodForm.id) {
      addDoc({ col: "periods", data });
    } else {
      updateDoc({ col: "periods", id: periodForm.id, data });
    }
    setPeriodsReset((prevValue) => {
      return !prevValue;
    });
    setDialogOpen(false);
    setPeriodForm(BLANK_PERIOD_FORM);
  };


  return (
    <>
      <NewPeriodButton setDialogOpen={setDialogOpen} />
      {periods &&
        periods.map((period) => (
          <PeriodCard
            key={period.id}
            period={period}
            setPeriodForm={setPeriodForm}
            setDialogOpen={setDialogOpen}
          />
        ))}
      <PeriodDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        periodForm={periodForm}
        setPeriodForm={setPeriodForm}
        saveForm={saveForm}
      />
    </>
  );
}
