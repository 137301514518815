import { useRecoilValue } from "recoil";
import { studentSectionsAtom, objectivesAtom } from "../../recoil/atoms";
import ObjectivesSection from "./ObjectivesSection";

function ObjectivesContainer() {
  const studentSections = useRecoilValue(studentSectionsAtom);
  const objectives = useRecoilValue(objectivesAtom);

  return (
    <>
      {objectives && (
        <>
          {studentSections &&
            studentSections.map((section) => (
              <ObjectivesSection section={section} key={section.id} />
            ))}
        </>
      )}
    </>
  );
}

export default ObjectivesContainer;
