import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImagePreviewContainer from "../ImageUpload/ImagePreviewContainer";
import { EntryInterface } from "../../interfaces/interfaces";

type Props = {
  entry: EntryInterface;
};

function ImageAccordion({ entry }: Props) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Manage Images</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ImagePreviewContainer col="entries" id={entry.id} images={entry.images} forPrint={false} />
      </AccordionDetails>
    </Accordion>
  );
}

export default ImageAccordion;
