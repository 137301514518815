import { Route, Routes } from "react-router-dom";
import LoginContainer from "./Login/LoginContainer";
import StaffPage from "./pages/StaffPage";
import CustomRoute from "./CustomRoute";
import { PERMISSION } from "./libraries/objects";
import SettingsPage from "./pages/SettingsPage";
import PrintPage from "./pages/PrintPage";
import ClassPage from "./pages/ClassPage";
import StudentPage from "./pages/StudentPage";
import UploadAttendancePage from "./pages/UploadAttendancePage";
import UploadMClassPage from "./pages/UploadMClassPage";
import UploadStandardsPage from "./pages/UploadStandardsPage";
import UploadTestResultsContainer from "./components/UploadTestResults/UploadTestResultsContainer";
import UpdateObjectivesWithGrades from "./dbchanges/UpdateObjectivesWithGrades";

export const routes = (
  <Routes>
    <Route path="/login" element={<LoginContainer />} />
    <Route
      path="/staff"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <StaffPage />
        </CustomRoute>
      }
    />
    <Route
      path="/"
      element={
        <CustomRoute permission={null}>
          <ClassPage />
        </CustomRoute>
      }
    />
    <Route
      path="/settings"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <SettingsPage />
        </CustomRoute>
      }
    />
    <Route
      path="/class"
      element={
        <CustomRoute permission={null}>
          <ClassPage />
        </CustomRoute>
      }
    />
    <Route
      path="/student"
      element={
        <CustomRoute permission={null}>
          <StudentPage />
        </CustomRoute>
      }
    />
    <Route
      path="/print"
      element={
        <CustomRoute permission={null}>
          <PrintPage />
        </CustomRoute>
      }
    />
    <Route
      path="/upload-attendance"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <UploadAttendancePage />
        </CustomRoute>
      }
    />
    <Route
      path="/upload-mclass"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <UploadMClassPage />
        </CustomRoute>
      }
    />
    <Route
      path="/upload-standards"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <UploadStandardsPage />
        </CustomRoute>
      }
    />
    <Route
      path="/upload-test-results"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <UploadTestResultsContainer />
        </CustomRoute>
      }
    />
    <Route
      path="/update-objectives-with-grades"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <UpdateObjectivesWithGrades />
        </CustomRoute>
      }
    />
  </Routes>
);
