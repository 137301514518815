import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { PeriodInterface } from "../../interfaces/interfaces";
import { useState, useEffect } from "react";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { periodsAtom } from "../../recoil/atoms";


type Props = {
  period: PeriodInterface;
  setPeriodForm: (period: PeriodInterface) => void;
  setDialogOpen: (newValue: boolean) => void;
};

export default function PeriodCard({ period, setPeriodForm, setDialogOpen }: Props) {
  const [isActive, setIsActive] = useState(period.isActive);
  const [periods, setPeriods] = useRecoilState(periodsAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const handleEdit = () => {
    setPeriodForm(period);
    setDialogOpen(true);
  };

  useEffect(() => {
    setIsActive(period.isActive)
  }, [period])

  const handleToggleActive = async () => {
    setIsActive(!isActive);
    const newPeriods: PeriodInterface[] = [];
    periods.forEach((selectedPeriod) => {
      const currentIsActive = period.id === selectedPeriod.id ? true : false;
      newPeriods.push({ ...selectedPeriod, isActive: currentIsActive })
      updateDoc({ col: "periods", id: selectedPeriod.id, data: { isActive: currentIsActive } })
    });
    setPeriods(newPeriods);

  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Typography variant="h5">{period.label}</Typography>
          <Typography>
            <b>Narratives Due: </b>
            {period.dueDate}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button size="small" variant="contained" onClick={handleToggleActive} color={isActive ? "warning" : "primary"} sx={{ marginLeft: 2 }}>
            {isActive ? "Active" : "Make Active"}
          </Button>
          <Button size="small" variant="contained" onClick={handleEdit} sx={{ marginLeft: 2 }}>
            Edit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
