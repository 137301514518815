import { Box, Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import UploadMClassContainer from "../components/UploadMClass/UploadMClassContainer";

const UploadMClassPage = () => {
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Navbar />
        <Container maxWidth="md" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
        <UploadMClassContainer />
        </Container>
      </Box>
    </>
  );
};

export default UploadMClassPage;
