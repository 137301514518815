import { Card, Typography, Grid, Button } from "@mui/material";
import { entriesResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { ObjectiveInterface, EntryInterface } from "../../interfaces/interfaces";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { useEffect, useState } from "react";

type Props = {
  objective: ObjectiveInterface;
  entry: EntryInterface;
};

type SectionObjective = {
  objectiveId: string;
  status: "Grow" | "Glow";
};

function SetObjectiveCard({ objective, entry }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setEntriesReset = useSetRecoilState(entriesResetAtom);
  const [sectionObjective, setSectionObjective] = useState<SectionObjective | null>(null);

  useEffect(() => {
    setSectionObjective(null);
  }, [entry.id]);

  useEffect(() => {
    const tempSectionObjective = entry.objectives.find(
      (entryObjective) => entryObjective.objectiveId === objective.id
    );
    if (!tempSectionObjective) return;
    setSectionObjective(tempSectionObjective);
  }, [entry.objectives, objective.id]);

  const handleChangeObjective = async (status: "Glow" | "Grow") => {
    const oldObjectives = [...entry.objectives];
    console.log(entry.objectives);
    setSectionObjective({ objectiveId: objective.id, status: status });
    const index = oldObjectives.findIndex((x) => x.objectiveId === objective.id);
    if (index < 0) {
      oldObjectives.push({ objectiveId: objective.id, status: status });
      console.log("new status");
    } else {
      if (oldObjectives[index].status === status) {
        console.log("remove status");
        oldObjectives.splice(index, 1);
        setSectionObjective(null);
      } else {
        console.log("change status");
        oldObjectives.splice(index, 1, { objectiveId: objective.id, status: status });
      }
    }
    await updateDoc({ col: "entries", id: entry.id, data: { objectives: oldObjectives } });
    setEntriesReset((pV) => !pV);
    console.log("reset done");
  };

  return (
    <Card sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={0}>
        <Grid item sm={3}>
          <Button
            fullWidth
            variant="contained"
            color={sectionObjective && sectionObjective.status === "Grow" ? "error" : "info"}
            sx={{ borderTopRightRadius: "0%", borderBottomRightRadius: "0%" }}
            onClick={() => handleChangeObjective("Grow")}
          >
            <Typography variant="h6">Grow</Typography>
          </Button>
        </Grid>
        <Grid item sm={6} sx={{ backgroundColor: "#ffffff", textAlign: "center" }}>
          <Typography variant="body2">{objective.label}</Typography>
        </Grid>
        <Grid item sm={3}>
          <Button
            fullWidth
            variant="contained"
            color={sectionObjective && sectionObjective.status === "Glow" ? "success" : "info"}
            sx={{ borderTopLeftRadius: "0%", borderBottomLeftRadius: "0%" }}
            onClick={() => handleChangeObjective("Glow")}
          >
            <Typography variant="h6">Glow</Typography>
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default SetObjectiveCard;
