import React, { useEffect, FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Container, CircularProgress, Avatar, Button, Box } from "@mui/material";
import Copyright from "./Copyright";
import { AuthContext } from "../providers/AuthProvider";

const LoginContainer: FC = () => {
  const navigate = useNavigate();
  const { currentAuthUser, loading, signInWithGoogle } = useContext(AuthContext);

  useEffect(() => {
    if (!loading && currentAuthUser) {
      navigate("/");
    }
  }, [currentAuthUser, navigate, loading]);

  return (
    <Container component="main" maxWidth="xs" sx={{ minHeight: "100vh", pt: 8 }}>
      {loading ? (
        <Box sx={{textAlign: "center"}}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Paper
          sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 4 }}
        >
          <Avatar sx={{ width: 200, height: 200 }} src="../TGPIcon.png"></Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={signInWithGoogle}
            sx={{ mt: 2 }}
          >
            Sign In With Google
          </Button>
        </Paper>
      )}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginContainer;
