import {
  StaffInterface,
  PeriodInterface,
  HomeroomInterface,
  StudentInterface,
  SectionInterface,
  EntryInterface,
  ObjectiveInterface,
  TardyLogInterface,
  AttendanceRecordInterface,
  RosterOverrideInterface,
  MClassRecordInterface,
  StandardRecordInterface,
} from "../interfaces/interfaces";
import {
  OverviewDataRecord,
  PromotionIndicatorRecord,
  StudentCourseScoreRecord,
  StudentStandardScoreRecord,
} from "../types/types";

export const parseStaffResponse = (response: StaffInterface[]): StaffInterface[] =>
  response.map((staff: StaffInterface) => ({
    id: staff?.id ?? "",
    firstName: staff?.firstName ?? "",
    lastName: staff?.lastName ?? "",
    email: staff?.email ?? "",
    permissions: staff?.permissions ?? [],
    homerooms: staff?.homerooms ?? [],
    active: staff?.active ?? "",
  }));

export const parsePeriodResponse = (response: PeriodInterface[]): PeriodInterface[] =>
  response.map((period: PeriodInterface) => ({
    id: period?.id ?? "",
    label: period?.label ?? "",
    dueDate: period?.dueDate ?? "",
    isActive: period?.isActive ?? "",
  }));

export const parseHomeroomResponse = (response: HomeroomInterface[]): HomeroomInterface[] =>
  response.map((record: HomeroomInterface) => ({
    id: record?.id ?? "",
    name: record?.name ?? "",
    grade: record?.grade ?? "",
    active: record?.active ?? true,
    gradeId: record?.gradeId ?? "",
  }));

export const parseStudentResponse = (response: StudentInterface[]): StudentInterface[] =>
  response.map((student: StudentInterface) => ({
    id: student?.id ?? "",
    childFirstName: student?.childFirstName ?? "",
    childLastName: student?.childLastName ?? "",
    enrollStatus: student?.enrollStatus ?? "",
    homeroom: student?.homeroom ?? "",
    SID: student?.SID ?? "",
  }));

export const parseEntryResponse = (response: EntryInterface[]): EntryInterface[] =>
  response.map((entry: EntryInterface) => ({
    id: entry?.id ?? "",
    authorEmail: entry?.authorEmail ?? "",
    html: entry?.html ?? "",
    periodId: entry?.periodId ?? "",
    sectionType: entry?.sectionType ?? "",
    sectionId: entry?.sectionId ?? "",
    homeroomId: entry?.homeroomId ?? "",
    studentId: entry?.studentId ?? "",
    images: entry?.images ?? [],
    objectives: entry?.objectives ?? [],
  }));

export const parseSectionResponse = (response: SectionInterface[]): SectionInterface[] =>
  response.map((section: SectionInterface) => ({
    id: section?.id ?? "",
    label: section?.label ?? "",
    description: section?.description ?? "",
    sectionType: section?.sectionType ?? "",
    order: section?.order ?? 0,
  }));

export const parseObjectiveResponse = (response: ObjectiveInterface[]): ObjectiveInterface[] =>
  response.map((record: ObjectiveInterface) => ({
    id: record?.id ?? "",
    label: record?.label ?? "",
    sectionId: record?.sectionId ?? "",
    order: record?.order ?? 0,
    gradeIds: record?.gradeIds ?? [],
    grades: record.grades ?? {
      KINDERGARTEN: false,
      LOWER_ELEMENTARY: false,
      UPPER_ELEMENTARY: false,
    },
  }));

export const parseRosterOverrideResponse = (
  response: RosterOverrideInterface[]
): RosterOverrideInterface[] =>
  response.map((override: RosterOverrideInterface) => ({
    id: override?.id ?? "",
    studentId: override?.studentId ?? "",
    homeroomId: override?.homeroomId ?? "",
    periodId: override?.periodId ?? 0,
  }));

export const parseTardyLogResponse = (response: TardyLogInterface[]): TardyLogInterface[] =>
  response.map((log: TardyLogInterface) => ({
    id: log?.id ?? "",
    SID: log?.SID ?? "",
    seconds: log?.seconds ?? 0,
    studentId: log?.studentId ?? "",
    timestamp: log?.timestamp ?? null,
    millis: log?.millis ?? 0,
  }));

export const parseAttendanceRecordResponse = (
  response: AttendanceRecordInterface[]
): AttendanceRecordInterface[] =>
  response.map((record: AttendanceRecordInterface) => ({
    id: record?.id ?? "",
    SID: record?.SID ?? "",
    grade: record?.grade ?? "",
    studentName: record?.studentName ?? "",
    absenceDate: record?.absenceDate ?? "",
    absencePeriod: record?.absencePeriod ?? "",
    absenceCode: record?.absenceCode ?? "",
  }));

export const parseMClassRecordResponse = (
  response: MClassRecordInterface[]
): MClassRecordInterface[] =>
  response.map((record: MClassRecordInterface) => ({
    id: record?.id ?? "",
    SID: record?.SID ?? "",
    benchmarkPeriod: record?.benchmarkPeriod ?? "",
    firstName: record?.firstName ?? "",
    lastName: record?.lastName ?? "",
    compositeLevel: record?.compositeLevel ?? "",
    compositeScore: record?.compositeScore ?? "",
    schoolYear: record?.schoolYear ?? "",
  }));

export const parseStandardRecordResponse = (
  response: StandardRecordInterface[]
): StandardRecordInterface[] =>
  response.map((record: StandardRecordInterface) => ({
    id: record?.id ?? "",
    grade: record?.grade ?? "",
    subject: record?.subject ?? "",
    key: record?.key ?? "",
    description: record?.description ?? "",
  }));

export const parseStudentStandardScoresResponse = (
  response: StudentStandardScoreRecord[]
): StudentStandardScoreRecord[] =>
  response.map((record: StudentStandardScoreRecord) => ({
    id: record?.id ?? "",
    standardKey: record?.standardKey ?? "",
    percentage: record?.percentage ?? "",
    dateTaken: record?.dateTaken ?? "",
    SID: record?.SID ?? "",
    createdAt: record?.createdAt ?? null,
    lastUpdatedAt: record?.lastUpdatedAt ?? null,
  }));

export const parseStudentCourseScoresResponse = (
  response: StudentCourseScoreRecord[]
): StudentCourseScoreRecord[] =>
  response.map((record: StudentCourseScoreRecord) => ({
    id: record?.id ?? "",
    SID: record?.SID ?? "",
    lastUpdatedAt: record?.lastUpdatedAt ?? null,
    createdAt: record?.createdAt ?? null,
    grade: record?.grade ?? "",
    subject: record?.subject ?? "",
    score: record?.score ?? "",
    level: record?.level ?? "",
    dateTaken: record?.dateTaken ?? "",
  }));

export const parsePromotionIndicatorRecordResponse = (
  response: PromotionIndicatorRecord[]
): PromotionIndicatorRecord[] =>
  response.map((record: PromotionIndicatorRecord) => ({
    id: record?.id ?? "",
    SID: record?.SID ?? "",
    lastUpdatedAt: record?.lastUpdatedAt ?? null,
    createdAt: record?.createdAt ?? null,
    onTrack: record?.onTrack ?? false,
    periodId: record?.periodId ?? "",
  }));

export const parseOverviewResponse = (response: OverviewDataRecord[]): OverviewDataRecord[] =>
  response.map((record: OverviewDataRecord) => ({
    id: record?.id ?? "",
    sid: record?.sid ?? "",
    lastUpdatedAt: record?.lastUpdatedAt ?? "",
    createdAt: record?.createdAt ?? "",
    subject: record?.subject ?? "",
    testRITScore: record?.testRITScore ?? "",
    achievementQuintile: record?.achievementQuintile ?? "",
    percentCorrect: record?.percentCorrect ?? "",
    testPercentile: record?.testPercentile ?? "",
    termName: record?.termName ?? "",
    rapidGuessingPercentage: record?.rapidGuessingPercentage ?? "",
    lexileScore: record?.lexileScore ?? "",
    projectedProficiencyLevel: record?.projectedProficiencyLevel ?? "",
  }));
