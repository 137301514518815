import { ImageDetails, TardyLogInterface } from "../interfaces/interfaces";

export const generateOneImageRow = ({ image, ratio }: { ratio: number; image: ImageDetails }) => {
  const tempImage = { ...image };
  if (ratio < 0.75) {
    tempImage.columns = { xs: 8, sm: 8, md: 6 };
  } else if (ratio < 1.2) {
    tempImage.columns = { xs: 6, sm: 6, md: 6 };
  } else {
    tempImage.columns = { xs: 6, sm: 6, md: 6 };
  }
  return tempImage;
};

export const generateTwoImageRow = ({
  smallerImg,
  largerImg,
}: {
  smallerImg: number;
  largerImg: number;
}) => {
  const columns = { smallerImg: { xs: 12, sm: 12, md: 12 }, largerImg: { xs: 12, sm: 12, md: 12 } };
  if (smallerImg < 0.75 && largerImg < 0.75) {
    columns.smallerImg = { xs: 6, sm: 6, md: 6 };
    columns.largerImg = { xs: 6, sm: 6, md: 6 };
  } else if (smallerImg < 0.75 && largerImg < 1.2) {
    columns.smallerImg = { xs: 7, sm: 7, md: 7 };
    columns.largerImg = { xs: 5, sm: 5, md: 5 };
  } else if (smallerImg < 0.75 && largerImg >= 1.2) {
    columns.smallerImg = { xs: 8, sm: 8, md: 8 };
    columns.largerImg = { xs: 4, sm: 4, md: 4 };
  } else if (smallerImg < 1.2 && largerImg < 1.2) {
    columns.smallerImg = { xs: 6, sm: 6, md: 6 };
    columns.largerImg = { xs: 6, sm: 6, md: 6 };
  } else if (smallerImg < 1.2 && largerImg >= 1.2) {
    columns.smallerImg = { xs: 7, sm: 7, md: 7 };
    columns.largerImg = { xs: 5, sm: 5, md: 5 };
  } else {
    columns.smallerImg = { xs: 6, sm: 6, md: 6 };
    columns.largerImg = { xs: 6, sm: 6, md: 6 };
  }
  return columns;
};

export const calcRatio = ({ height, width }: { height: number; width: number }) => {
  return Math.round((height / width) * 10) / 10;
};

export const sortArrayOfObjects = (array: { [key: string]: string }[], property: string) => {
  return array.sort((a, b) => (a[property] > b[property] ? 1 : -1));
};

export const createImageColumns = (imageList: ImageDetails[]) => {
  const tempArray: ImageDetails[] = [];
  let currentImage = 0;
  const totalImageCount = imageList.length;
  while (totalImageCount - currentImage >= 1) {
    const currentImageRatio = calcRatio({
      height: imageList[currentImage]?.dimensions?.height ?? 1,
      width: imageList[currentImage]?.dimensions?.width ?? 1,
    });
    if (totalImageCount - currentImage === 1) {
      const imageWithColumns = generateOneImageRow({
        image: imageList[currentImage],
        ratio: currentImageRatio,
      });
      tempArray.push(imageWithColumns);
      currentImage++;
    } else {
      const nextImageRatio = calcRatio({
        height: imageList[currentImage + 1]?.dimensions?.height ?? 1,
        width: imageList[currentImage + 1]?.dimensions?.width ?? 1,
      });
      const imageOneWithColumns = { ...imageList[currentImage] };
      const imageTwoWithColumns = { ...imageList[currentImage + 1] };
      if (currentImageRatio < nextImageRatio) {
        const columns = generateTwoImageRow({
          smallerImg: currentImageRatio,
          largerImg: nextImageRatio,
        });
        imageOneWithColumns.columns = columns.smallerImg;
        imageTwoWithColumns.columns = columns.largerImg;
      } else {
        const columns = generateTwoImageRow({
          smallerImg: nextImageRatio,
          largerImg: currentImageRatio,
        });
        imageOneWithColumns.columns = columns.largerImg;
        imageTwoWithColumns.columns = columns.smallerImg;
      }
      tempArray.push(imageOneWithColumns);
      tempArray.push(imageTwoWithColumns);
    }
    currentImage += 2;
  }
  return tempArray;
};

export const getIdOfFirstItem = (array: { id: string }[]) => {
  return array.length > 0 ? array[0].id : "";
};

type CalculateMissingTime = {
  earlyPickUpLogs: TardyLogInterface[];
  tardyLogs: TardyLogInterface[];
};

export const calculateTotalMinutesMissing = ({
  earlyPickUpLogs,
  tardyLogs,
}: CalculateMissingTime) => {
  const startTime = new Date("8:00AM");
  const startTimeHour = startTime.getHours();
  const startTimeMinute = startTime.getMinutes();
  const startTimeMinuteFromMidNight = startTimeHour * 60 + startTimeMinute;

  const endTime = new Date("3:45PM");
  const endTimeHour = endTime.getHours();
  const endTimeMinute = endTime.getMinutes();
  const endTimeMinuteFromMidNight = endTimeHour * 60 + endTimeMinute;

  let totalMissing = 0;

  let tardyMissingMinutes = 0;

  for (let log of tardyLogs) {
    if (log.timestamp) {
      const logTimeDate = log.timestamp.toDate();
      const logTimeHour = logTimeDate.getHours();
      const logTimeMinute = logTimeDate.getMinutes();
      const logTimeMinuteFromMidNight = logTimeHour * 60 + logTimeMinute;
      const diff = logTimeMinuteFromMidNight - startTimeMinuteFromMidNight;

      if (diff > 0) {
        tardyMissingMinutes += diff;
      }
    }
  }

  let earlyPickUpMissingMinutes = 0;

  for (let log of earlyPickUpLogs) {
    if (log.timestamp) {
      const logTimeDate = log.timestamp.toDate();
      const logTimeHour = logTimeDate.getHours();
      const logTimeMinute = logTimeDate.getMinutes();
      const logTimeMinuteFromMidNight = logTimeHour * 60 + logTimeMinute;
      const diff = endTimeMinuteFromMidNight - logTimeMinuteFromMidNight;

      if (diff > 0) {
        earlyPickUpMissingMinutes += diff;
      }
    }
  }
  totalMissing = tardyMissingMinutes + earlyPickUpMissingMinutes;

  if (totalMissing === 0) {
    return 0;
  }

  return totalMissing;
};
