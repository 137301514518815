import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { SectionInterface, EntryInterface } from "../../interfaces/interfaces";
import { useRecoilValue } from "recoil";
import {
  entriesAtom,
  selectedHomeroomIdAtom,
  selectedStudentIdAtom,
  selectedPeriodIdAtom,
} from "../../recoil/atoms";
import EntryHtml from "./EntryHtml";
import EntryObjectives from "./EntryObjectives";
import EntryImages from "./EntryImages";

type Props = {
  section: SectionInterface;
  showImages: boolean;
};

function Entry({ section, showImages }: Props) {
  const entries = useRecoilValue(entriesAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const [currentEntry, setCurrentEntry] = useState<EntryInterface | null>(null);

  useEffect(() => {
    setCurrentEntry(null);
    let foundEntry: EntryInterface | undefined = undefined;
    if (section.sectionType === "student") {
      foundEntry = entries?.find(
        (entry) =>
          entry.periodId === selectedPeriodId &&
          entry.studentId === selectedStudentId &&
          entry.sectionId === section.id
      );
    } else {
      foundEntry = entries?.find(
        (entry) =>
          entry.periodId === selectedPeriodId &&
          entry.homeroomId === selectedHomeroomId &&
          entry.sectionId === section.id
      );
    }

    if (foundEntry) {
      setCurrentEntry(foundEntry);
    }
  }, [selectedStudentId, selectedHomeroomId, selectedPeriodId, entries, section]);

  return (
    <>
      {currentEntry && (Number(currentEntry.html.length) > 12 || currentEntry.objectives.length > 0) && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h3" sx={{ pt: 2 }}>
            {section.label}
          </Typography>
          <EntryHtml entry={currentEntry} />
          {section.sectionType === "student" && <EntryObjectives entry={currentEntry} />}
          {showImages && <EntryImages entry={currentEntry} />}
        </Box>
      )}
    </>
  );
}

export default Entry;
