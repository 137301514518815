import { useContext } from "react";
import { Container, Backdrop, CircularProgress } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import StudentContainer from "../components/SectionTypes/StudentContainer";
import { useRecoilValue } from "recoil";
import { objectivesAtom, selectedHomeroomIdAtom, selectedPeriodIdAtom } from "../recoil/atoms";
import { AuthContext } from "../providers/AuthProvider";

const StudentPage = () => {
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const { currentAuthUser } = useContext(AuthContext);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const objectives = useRecoilValue(objectivesAtom);
  const loading = Boolean(
    !selectedPeriodId || !selectedHomeroomId || !currentAuthUser || !objectives
  );

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
        {loading ? (
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <StudentContainer />
        )}
      </Container>
    </>
  );
};

export default StudentPage;
