import { useEffect, useContext, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Typography, Box } from "@mui/material";
import {
  selectedStudentIdAtom,
  entriesAtom,
  selectedPeriodIdAtom,
  selectedHomeroomIdAtom,
  studentSectionsAtom,
  entriesResetAtom,
} from "../../recoil/atoms";
import useAddDoc from "../../hooks/useAddDoc";
import RichTextEditorContainer from "../RichTextEditor/RichTextEditorContainer";
import { AuthContext } from "../../providers/AuthProvider";
import SelectStudent from "../Shared/SelectStudent";
import StudentDataContainer from "../StudentData/StudentDataContainer";

type EMAIL = string;
type FIREBASE_ID = string;

type StartingForm = {
  sectionId: string | null;
  sectionType: string;
  periodId: FIREBASE_ID;
  authorEmail: EMAIL;
  html: string;
  homeroomId: FIREBASE_ID;
  studentId: FIREBASE_ID;
  id: FIREBASE_ID;
  images: [];
  objectives: [];
};

export default function IndividualizedContainer() {
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const studentSections = useRecoilValue(studentSectionsAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);

  const entries = useRecoilValue(entriesAtom);
  const setEntriesReset = useSetRecoilState(entriesResetAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const { currentAuthUser } = useContext(AuthContext);

  const startingForm = useMemo<StartingForm>(
    () => ({
      sectionId: null,
      sectionType: "student",
      periodId: selectedPeriodId,
      authorEmail: currentAuthUser?.email ?? "",
      html: "",
      homeroomId: selectedHomeroomId,
      studentId: selectedStudentId,
      id: "",
      images: [],
      objectives: [],
    }),
    [selectedPeriodId, currentAuthUser, selectedHomeroomId, selectedStudentId]
  );

  useEffect(() => {
    if (!entries || !selectedStudentId || !studentSections) return;
    const checkIfSectionsExist = async () => {
      const currentEntries = entries.filter(
        (entry) => entry.studentId === selectedStudentId && entry.periodId === selectedPeriodId
      );
      const requestPromises: Array<Promise<string | null>> = [];
      let reset = false;

      studentSections.forEach((section) => {
        const entryExist = currentEntries.filter((entry) => entry.sectionId === section.id);
        if (entryExist.length === 0) {
          const entryForm = { ...startingForm, sectionId: section.id };
          requestPromises.push(addDoc({ col: "entries", data: entryForm }));
          reset = true;
        }
      });
      await Promise.all(requestPromises);
      if (reset) {
        setEntriesReset((pV) => !pV);
      }
    };
    checkIfSectionsExist();
  }, [
    selectedStudentId,
    entries,
    addDoc,
    startingForm,
    setEntriesReset,
    selectedPeriodId,
    studentSections,
  ]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h2">Student Sections</Typography>
          <Typography variant="body2" sx={{ padding: 2 }}>
            The sections below will only be printed for this specific student. Any section left
            blank will not be printed on the narrative. For example, if Special Education is not
            filled it, there will not be a special education section on the narrative.
          </Typography>
        </Box>
        <SelectStudent />
      </Box>
      {studentSections &&
        Boolean(selectedStudentId) &&
        studentSections.map((section) => (
          <RichTextEditorContainer key={section.id} section={section} sectionType="student" />
        ))}
      {Boolean(selectedStudentId) && <StudentDataContainer />}
      <div></div>
    </>
  );
}
