import { Card, CardContent, Typography, Grid, Avatar, CardHeader, Button } from "@mui/material";
import SetPermissions from "./SetPermissions";
import { StaffInterface } from "../../interfaces/interfaces";
import { useRecoilState } from "recoil";
import { staffAtom } from "../../recoil/atoms";
import useUpdateDoc from "../../hooks/useUpdateDoc";

type Props = {
  staff: StaffInterface;
};

export default function StaffCard({ staff }: Props) {
  const [staffList, setStaffList] = useRecoilState(staffAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const handleRemove = () => {
    const updatedStaffList = staffList.map(s => {
      if (s.id === staff.id) {
        updateDoc({ col: "staff", id: staff.id, data: { active: false } })
        return { ...s, active: false }; 
      }
      return s;
    });
    setStaffList(updatedStaffList);
  };
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardHeader
          avatar={<Avatar sx={{ width: 70, height: 70 }} />}
          title={
            <Typography variant="cardTitle">
              {staff.firstName} {staff.lastName}
            </Typography>
          }
        />
        <CardContent>
          <SetPermissions staff={staff} />
        </CardContent>
        <Button size="small" variant="contained" color="error" onClick={handleRemove} fullWidth>
          Remove
        </Button>
      </Card>
    </Grid>
  );
}
