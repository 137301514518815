import { atom, selector } from "recoil";
import { GetRecoilValue } from "recoil";
import { StudentCourseScoreRecord, StudentStandardScoreRecord } from "../types/types";

const courseScoresWithSixDigitIds = ({ get }: { get: GetRecoilValue }) => {
  const records = get(rawStudentCourseScoresAtom);
  if (!records) return [];
  const tempArray: StudentCourseScoreRecord[] = [];
  records.forEach((record) => {
    const zerosToAdd = 6 - record.SID.length;
    let tempSID = record.SID;
    for (let i = 0; i < zerosToAdd; i++) {
      tempSID = "0" + tempSID;
    }
    tempArray.push({ ...record, SID: tempSID });
  });
  return tempArray;
};

const standardScoresWithSixDigitIds = ({ get }: { get: GetRecoilValue }) => {
  const records = get(rawStudentStandardScoresAtom);
  if (!records) return [];
  const tempArray: StudentStandardScoreRecord[] = [];
  records.forEach((record) => {
    const zerosToAdd = 6 - record.SID.length;
    let tempSID = record.SID;
    for (let i = 0; i < zerosToAdd; i++) {
      tempSID = "0" + tempSID;
    }
    tempArray.push({ ...record, SID: tempSID });
  });
  return tempArray;
};

export const rawStudentCourseScoresAtom = atom<StudentCourseScoreRecord[]>({
  key: "rawStudentCourseScores",
  default: [],
});

export const rawStudentStandardScoresAtom = atom<StudentStandardScoreRecord[]>({
  key: "rawStudentStandardScores",
  default: [],
});

export const studentCourseScoresAtom = selector({
  key: "studentCourseScores",
  get: courseScoresWithSixDigitIds,
});

export const studentStandardScoresAtom = selector({
  key: "studentStandardScores",
  get: standardScoresWithSixDigitIds,
});
