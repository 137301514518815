import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	selectedHomeroomIdAtom,
	loggedInStaffAtom,
	selectedStudentIdAtom,
	availableHomeroomsAtom,
	entriesResetAtom,
} from '../../recoil/atoms';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { PERMISSION } from '../../libraries/objects';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const mobileSX = {
	fontWeight: 800,
	borderColor: '#fff',
	fontSize: '.8em',
	maxWidth: '200px',
	color: '#000',
};

const webSX = {
	fontWeight: 800,
	borderColor: '#fff',
	fontSize: '.8em',
	color: '#fff',
	mr: 2,
};

export default function HomeroomSelect() {
	const [selectedHomeroomId, setSelectedHomeroomId] = useRecoilState<string>(
		selectedHomeroomIdAtom
	);
	const setSelectedStudentId = useSetRecoilState(selectedStudentIdAtom);
	const setEntriesReset = useSetRecoilState(entriesResetAtom);
	const loggedInStaff = useRecoilValue(loggedInStaffAtom);
	const availableHomerooms = useRecoilValue(availableHomeroomsAtom);
	const { width } = useWindowDimensions();
	const mobileView = width < 900;
	const handleChange = useCallback(
		(event: SelectChangeEvent) => {
			setSelectedHomeroomId(event.target.value);
			setSelectedStudentId('');
			setEntriesReset((pV) => !pV);
		},
		[setSelectedHomeroomId, setSelectedStudentId, setEntriesReset]
	);

	return (
		<>
			{loggedInStaff && selectedHomeroomId && (
				<Select
					id="select-homeroom"
					sx={mobileView ? mobileSX : webSX}
					fullWidth={mobileView ? true : false}
					value={selectedHomeroomId}
					label="Homeroom"
					className="menuSelect"
					onChange={handleChange}
				>
					{loggedInStaff.permissions?.includes(PERMISSION.SEE_ALL_ROSTERS) && (
						<MenuItem value="all">All</MenuItem>
					)}
					<MenuItem value="none">None</MenuItem>
					{loggedInStaff.permissions?.includes(PERMISSION.SEE_ALL_ROSTERS) &&
						availableHomerooms &&
						availableHomerooms.map((homeroom) => (
							<MenuItem key={homeroom.id} value={homeroom.id}>
								{homeroom.name}
							</MenuItem>
						))}
					{!loggedInStaff.permissions?.includes(PERMISSION.SEE_ALL_ROSTERS) &&
						availableHomerooms &&
						availableHomerooms.map((homeroom) => (
							<MenuItem key={homeroom.id} value={homeroom.id}>
								{homeroom.name}
							</MenuItem>
						))}
				</Select>
			)}
		</>
	);
}
