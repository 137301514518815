import { Grid, Divider, Typography, Box } from "@mui/material";
import { ObjectiveInterface, EntryInterface } from "../../interfaces/interfaces";
import { objectivesAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

type Props = {
  entry: EntryInterface | undefined;
};

function EntryObjectives({ entry }: Props) {
  const objectives = useRecoilValue(objectivesAtom);

  const objectivesObject: any = {};
  objectives?.forEach((objective) => {
    objectivesObject[objective.id] = { ...objective };
  });

  const glows: ObjectiveInterface[] = [];
  const grows: ObjectiveInterface[] = [];

  entry?.objectives.forEach((objective) => {
    if (objectivesObject[objective.objectiveId]) {
      if (objective.status === "Glow") {
        glows.push(objectivesObject[objective.objectiveId]);
      } else if (objective.status === "Grow") {
        grows.push(objectivesObject[objective.objectiveId]);
      }
    }
  });

  return (
    <>
      {(glows.length > 0 || grows.length > 0) && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container className="no-break" spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h6">Glows</Typography>
              <Divider />
              {glows.map((glow) => (
                <Typography key={glow.label} variant="body2">
                  {glow.label}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Grows</Typography>
              <Divider />
              {grows.map((grow) => (
                <Typography key={grow.label} variant="body2">
                  {grow.label}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default EntryObjectives;
