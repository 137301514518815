import React from "react";
import { rosterOverridesAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import RosterOverrideCard from "./RosterOverrideCard";

function RosterOverridesList() {
  const rosterOverrides = useRecoilValue(rosterOverridesAtom);
  return (
    <>
        {rosterOverrides && rosterOverrides.map((rosterOverride)=>(
            <RosterOverrideCard rosterOverride={rosterOverride} key={rosterOverride.id} />
        ))}
    </>
  );
}



export default RosterOverridesList;