import { useState, useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	studentsAtom,
	homeroomsAtom,
	selectedPeriodIdAtom,
	rosterOverridesResetAtom,
} from '../../recoil/atoms';
import {
	Select,
	MenuItem,
	SelectChangeEvent,
	Grid,
	Typography,
	FormControl,
	InputLabel,
	Button,
	Autocomplete,
	TextField,
	Divider,
} from '@mui/material';
import useAddDoc from '../../hooks/useAddDoc';
import { StudentInterface } from '../../interfaces/interfaces';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function NewRosterOverride() {
	const [selectedStudentId, setSelectedStudentId] = useState<string | null>(
		'none'
	);
	const [selectedHomeroomId, setSelectedHomeroomId] = useState<string>('none');
	const [sortedStudents, setSortedStudents] = useState<StudentInterface[]>([]);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
	const students = useRecoilValue(studentsAtom);
	const homerooms = useRecoilValue(homeroomsAtom);
	const setRosterOverridesReset = useSetRecoilState(rosterOverridesResetAtom);
	const { sendRequest: addDoc } = useAddDoc();
	const { width } = useWindowDimensions();

	useEffect(() => {
		if (selectedStudentId === 'none' || selectedHomeroomId === 'none') {
			setButtonDisabled(true);
		} else {
			setButtonDisabled(false);
		}
	}, [selectedHomeroomId, selectedStudentId]);

	const handleStudentChange = useCallback(
		(event: any, newValue: StudentInterface | null) => {
			setSelectedStudentId(newValue ? newValue.id : 'none');
		},
		[]
	);

	const handleHomeroomChange = useCallback((event: SelectChangeEvent) => {
		setSelectedHomeroomId(event.target.value);
	}, []);

	useEffect(() => {
		if (!students) return;
		const toSort = students.filter(
			(student) => student.enrollStatus === 'Actively Enrolled'
		);
		const sortedArray = toSort.sort((a, b) =>
			a['childLastName'] > b['childLastName'] ? 1 : -1
		);
		setSortedStudents(sortedArray);
	}, [students]);

	const saveOverride = async () => {
		await addDoc({
			col: 'rosterOverrides',
			data: {
				studentId: selectedStudentId,
				homeroomId: selectedHomeroomId,
				periodId: selectedPeriodId,
			},
		});
		setSelectedHomeroomId('none');
		setSelectedStudentId('none');
		setRosterOverridesReset((pV) => !pV);
	};

	return (
		<>
			<Grid container spacing={2} sx={{ mt: 1 }}>
				<Grid item sm={12} xs={12} md={3}>
					<Autocomplete
						fullWidth
						disablePortal
						options={sortedStudents}
						onChange={handleStudentChange}
						getOptionLabel={(student) =>
							student.childFirstName + ' ' + student.childLastName
						}
						renderInput={(params) => (
							<TextField
								{...params}
								sx={{ width: '100%' }}
								label="Select Student"
							/>
						)}
					/>
				</Grid>
				<Grid item sm={12} xs={12} md={3}>
					<Typography textAlign={width < 900 ? 'center' : 'left'} variant="h6">
						Homeroom to Override to For the Selected Narrative Period:
					</Typography>
				</Grid>
				<Grid item sm={12} xs={12} md={3}>
					<FormControl fullWidth>
						<InputLabel>Homeroom</InputLabel>
						<Select
							value={selectedHomeroomId}
							onChange={handleHomeroomChange}
							fullWidth
							label="Homeroom"
						>
							<MenuItem value="none">No Homeroom Selected</MenuItem>
							{homerooms &&
								homerooms.map((homeroom) => (
									<MenuItem key={homeroom.id} value={homeroom.id}>
										{homeroom.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item sm={12} xs={12} md={3}>
					<Button
						disabled={buttonDisabled}
						variant="contained"
						fullWidth
						sx={{ height: 56 }}
						onClick={saveOverride}
					>
						Submit
					</Button>
				</Grid>
			</Grid>
			<Divider sx={{ mt: 2 }} />
		</>
	);
}

export default NewRosterOverride;
