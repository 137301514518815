import { useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import ObjectivesContainer from './ObjectivesContainer';
import PeriodsContainer from './PeriodsContainer';
import SectionTypesContainer from './SectionsContainer';
import SettingsSideMenu from './SettingsSideMenu';
import { SettingsSections } from '../../libraries/objects';
import RosterOverrideContainer from './RosterOverrideContainer';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function SettingsContainer() {
	const [activeSettingSection, setActiveSettingSection] = useState<string>(
		SettingsSections.OBJECTIVES
	);
	const { width } = useWindowDimensions();

	const sideMenuHeight = window.innerHeight - 64;
	return (
		<>
			{width > 900 ? (
				<Grid container spacing={0}>
					<Grid item xs={3}>
						<Box sx={{ height: sideMenuHeight, backgroundColor: '#eee' }}>
							<SettingsSideMenu
								setActiveSettingSection={setActiveSettingSection}
							/>
						</Box>
					</Grid>
					<Grid item xs={9}>
						<Box
							sx={{ height: sideMenuHeight, overflow: 'scroll', pl: 2, pr: 2 }}
						>
							<Typography
								sx={{ textAlign: 'center', pt: 2, marginBottom: '8px' }}
								variant="h2"
							>
								Settings
							</Typography>
							{activeSettingSection === SettingsSections.SECTIONS && (
								<>
									<Typography sx={{ pt: 2 }} variant="h4">
										Narrative Sections
									</Typography>
									<SectionTypesContainer />
								</>
							)}
							{activeSettingSection === SettingsSections.PERIODS && (
								<>
									<Typography sx={{ pt: 2 }} variant="h4">
										Narrative Periods
									</Typography>
									<PeriodsContainer />
								</>
							)}
							{activeSettingSection === SettingsSections.OBJECTIVES && (
								<>
									<Typography sx={{ pt: 2 }} variant="h4">
										Narrative Objectives
									</Typography>
									<ObjectivesContainer />
								</>
							)}
							{activeSettingSection === SettingsSections.OVERRIDE && (
								<>
									<Typography sx={{ pt: 2 }} variant="h4">
										Roster Override
									</Typography>
									<RosterOverrideContainer />
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Box sx={{ overflow: 'scroll', pl: 2, pr: 2 }}>
							<Typography
								sx={{ textAlign: 'center', pt: 2, marginBottom: '8px' }}
								variant="h2"
							>
								Settings
							</Typography>
							<SettingsSideMenu
								setActiveSettingSection={setActiveSettingSection}
							/>
							{activeSettingSection === SettingsSections.SECTIONS && (
								<>
									<Typography sx={{ pt: 2, textAlign: 'center' }} variant="h4">
										Narrative Sections
									</Typography>
									<SectionTypesContainer />
								</>
							)}
							{activeSettingSection === SettingsSections.PERIODS && (
								<>
									<Typography sx={{ pt: 2, textAlign: 'center' }} variant="h4">
										Narrative Periods
									</Typography>
									<PeriodsContainer />
								</>
							)}
							{activeSettingSection === SettingsSections.OBJECTIVES && (
								<>
									<Typography sx={{ pt: 2, textAlign: 'center' }} variant="h4">
										Narrative Objectives
									</Typography>
									<ObjectivesContainer />
								</>
							)}
							{activeSettingSection === SettingsSections.OVERRIDE && (
								<>
									<Typography sx={{ pt: 2, textAlign: 'center' }} variant="h4">
										Roster Override
									</Typography>
									<RosterOverrideContainer />
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			)}
		</>
	);

}
