import { Typography, Paper, Switch, FormControlLabel } from "@mui/material";
import TardyData from "./TardyData";
import AttendanceData from "./AttendanceData";
import MClassData from "./MClassData";
import AcademicDataContainer from "./AcademicDataContainer";
import { promotionIndicatorAtom, promotionIndicatorResetAtom, selectedPeriodIdAtom, selectedStudentIdAtom, studentsAtom, studentsObjAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useState, useEffect, ChangeEvent } from "react";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import useAddDoc from "../../hooks/useAddDoc";

function StudentDataContainer() {
  const [onTrack, setOnTrack] = useState(true);
  const students = useRecoilValue(studentsAtom);
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom)
  const promotionIndicators = useRecoilValue(promotionIndicatorAtom);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const currentStudent = students.find((student) => selectedStudentId === student.id);
  const currentStudentPromotionIndicator = promotionIndicators.find((indicator) => indicator.SID === currentStudent?.SID && indicator.periodId === selectedPeriodId);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const { sendRequest: addDoc } = useAddDoc();
  const setPromotionIndicatorReset = useSetRecoilState(promotionIndicatorResetAtom);

  useEffect(() => {
    if (currentStudentPromotionIndicator) {
      setOnTrack(currentStudentPromotionIndicator.onTrack)
    }
  }, [currentStudentPromotionIndicator])

  const handleSwitchChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!currentStudent || !selectedPeriodId) return;
    setOnTrack(e.target.checked)
    if (currentStudentPromotionIndicator) {
      await updateDoc({ col: "promotionIndicator", data: { onTrack: e.target.checked }, id: currentStudentPromotionIndicator.id })
    } else {
      await addDoc({
        col: "promotionIndicator", data: {
          SID: currentStudent.SID,
          onTrack: e.target.checked,
          periodId: selectedPeriodId

        }
      })
    }
    setPromotionIndicatorReset((pV) => !pV)
  }


  return (
    <Paper sx={{ padding: 2, mt: 2, mb: 2 }}>
      <Typography variant="h4">Student Data</Typography>
      <Typography sx={{ mt: 2 }} variant="h5">
        Attendance Data
      </Typography>
      <Typography>Is Student On Track To Be Promoted</Typography>
      <FormControlLabel control={<Switch checked={onTrack} color="primary" onChange={handleSwitchChange} />} label={onTrack ? "Yes" : "No"} />
      <TardyData />
      <AttendanceData />
      {/* <MClassData /> */}
      <AcademicDataContainer />
    </Paper>
  );
}

export default StudentDataContainer;
