import React, { SyntheticEvent, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { gradesAtom, objectivesResetAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ObjectiveInterface, GradeInterface } from "../../interfaces/interfaces";
import useUpdateDoc from "../../hooks/useUpdateDoc";

type Props = {
  open: boolean;
  setOpen: (prevValue: boolean) => void;
  objective: ObjectiveInterface;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

type ObjectiveForm = {
  label: string;
  order: number;
  gradeIds: string[];
  sectionId: string;
  id: string;
};

const EditObjectiveDialog = ({ open, setOpen, objective }: Props) => {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const grades = useRecoilValue(gradesAtom);
  const setObjectivesReset = useSetRecoilState(objectivesResetAtom);
  const [objectiveForm, setObjectiveForm] = useState<ObjectiveForm>(objective);

  const handleTextChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setObjectiveForm((pV) => ({ ...pV, [formState.name]: formState.value }));
  };

  const handleNumberChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setObjectiveForm((pV) => ({ ...pV, [formState.name]: Number(formState.value) }));
  };

  const handleSave = async () => {
    await updateDoc({ col: "objectives", data: objectiveForm, id: objective.id });
    setObjectivesReset((pV) => !pV);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //TODO: Use the dictonary format from the attendance upload to dynamically set the key

  const handleGradeChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    const gradeIds = [...objectiveForm.gradeIds];
    if (gradeIds.includes(formState.name)) {
      gradeIds.splice(gradeIds.indexOf(formState.name), 1);
    } else {
      gradeIds.push(formState.name);
    }
    setObjectiveForm((pV) => ({ ...pV, gradeIds: gradeIds }));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: 44 }}>Edit Objective</DialogTitle>
      <DialogContent>
        <TextField
          label="Objective Name"
          fullWidth
          value={objectiveForm.label}
          onChange={handleTextChange}
          name="label"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Order"
          fullWidth
          value={String(objectiveForm.order)}
          onChange={handleNumberChange}
          name="order"
          type="number"
          sx={{ mt: 2 }}
        />
        {grades.map((grade) => (
          <FormControlLabel
            key={grade.id}
            control={
              <Switch
                checked={objectiveForm.gradeIds.includes(grade.id)}
                onChange={handleGradeChange}
                name={grade.id}
              />
            }
            label={grade.name}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(EditObjectiveDialog);
