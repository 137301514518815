import React from "react";
import { Box } from "@mui/material";
import ReactQuill from "react-quill";
import "quill-paste-smart";
import "react-quill/dist/quill.snow.css";
import { toolbarOptions } from "../../libraries/objects";

type Props = {
  text: ReactQuill.Value;
  handleOnFocus: () => void;
  handleOnBlur: () => void;
  handleTextChange: (newValue: ReactQuill.Value, delta: any , source: any) => void;
};

const SectionEditor = ({ handleOnBlur, handleOnFocus, text, handleTextChange }: Props) => {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <ReactQuill
          theme="snow"
          value={text}
          onChange={handleTextChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          style={{ marginTop: "16px" }}
          modules={{
            toolbar: toolbarOptions,
            clipboard: {
              allowed: {
                tags: ["a", "u", "s", "i", "p", "br", "ul", "ol", "li", "span"],
                attributes: ["href"],
              },
            },
          }}
        />
      </Box>
    </>
  );
};

export default React.memo(SectionEditor);
