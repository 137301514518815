import { atom } from "recoil";
import { StandardRecordInterface } from "../interfaces/interfaces";
import { OverviewDataRecord } from "../types/types";

type FIREBASE_ID = string;

export const overviewDataAtom = atom<OverviewDataRecord[]>({
  key: "overviewData",
  default: [],
});
