import { Typography, Box, Chip } from "@mui/material";
import { SectionInterface } from "../../interfaces/interfaces";

type Props = {
  hasEdited: boolean;
  isSyncing: boolean;
  focused: boolean;
  section: SectionInterface;
};

enum colors {
  success = "#119914",
  error = "#B31425",
  warning = "#E6D43C",
}

function StatusChip({ isSyncing, focused, hasEdited, section }: Props) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h4">{section.label}</Typography>
      {hasEdited && !isSyncing && (
        <Chip label="Not Saved" sx={{ backgroundColor: colors.error, color: "#fff" }} />
      )}
      {!hasEdited && focused && !isSyncing && (
        <Chip label="Saved" sx={{ backgroundColor: colors.success, color: "#fff" }} />
      )}
      {isSyncing && hasEdited && (
        <Chip label="Saving..." sx={{ backgroundColor: colors.warning, color: "#fff" }} />
      )}
    </Box>
  );
}

export default StatusChip;
