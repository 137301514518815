import { useState, SyntheticEvent } from 'react';
import {
	Paper,
	Typography,
	TextField,
	Button,
	Select,
	MenuItem,
	Grid,
	SelectChangeEvent,
} from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { sectionsResetAtom } from '../../recoil/atoms';
import useAddDoc from '../../hooks/useAddDoc';
import useWindowDimensions from '../../hooks/useWindowDimensions';

type FormState = EventTarget & {
	name: string;
	value: string;
};

const blankForm = {
	label: '',
	description: '',
	sectionType: 'class',
	order: 1,
};

function NewSection() {
	const { sendRequest: addDoc } = useAddDoc();
	const sectionsReset = useSetRecoilState(sectionsResetAtom);

	const [sectionForm, setSectionForm] = useState(blankForm);
	const [isComplete, setIsComplete] = useState(false);

	const { width } = useWindowDimensions();
	const mobile = width > 900;

	const handleTextChange = (event: SyntheticEvent) => {
		const formState = event.target as FormState;
		setSectionForm((pV) => ({ ...pV, [formState.name]: formState.value }));
	};

	const handleSectionTypeChange = (event: SelectChangeEvent) => {
		setSectionForm((pV) => ({ ...pV, sectionType: event.target.value }));
	};

	const handleSave = async () => {
		await addDoc({ col: 'sections', data: sectionForm });
		sectionsReset((pV) => !pV);
		setIsComplete(true);
		window.setTimeout(() => {
			setIsComplete(false);
		}, 3000);
	};

	return mobile ? (
		<Paper sx={{ mt: 2, padding: 2 }}>
			<Typography variant="h6">Create New Section</Typography>
			<Grid container spacing={2} sx={{ mt: 1 }}>
				<Grid item sm={5}>
					<TextField
						label="Section Name"
						fullWidth
						value={sectionForm.label}
						onChange={handleTextChange}
						name="label"
					/>
				</Grid>
				<Grid item sm={2}>
					<TextField
						label="Order"
						fullWidth
						value={sectionForm.order}
						onChange={handleTextChange}
						name="order"
						type="number"
					/>
				</Grid>
				<Grid item sm={5}>
					<Select
						fullWidth
						value={sectionForm.sectionType}
						onChange={handleSectionTypeChange}
					>
						<MenuItem value="class">Class</MenuItem>
						<MenuItem value="student">Student</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Section Description"
						fullWidth
						value={sectionForm.description}
						onChange={handleTextChange}
						name="description"
					/>
					{isComplete && (
						<Typography sx={{ mt: 1, color: 'green' }}>
							Changes Saved
						</Typography>
					)}
				</Grid>
				<Grid item sm={2}>
					<Button
						variant="outlined"
						fullWidth
						sx={{ padding: 1, mt: 1 }}
						onClick={handleSave}
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		</Paper>
	) : (
		<Paper sx={{ mt: 2, padding: 2, textAlign: 'center' }}>
			<Typography variant="h6">Create New Section</Typography>
			<Grid container spacing={2} sx={{ mt: 1 }}>
				<Grid item xs={12}>
					<TextField
						label="Section Name"
						fullWidth
						value={sectionForm.label}
						onChange={handleTextChange}
						name="label"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Order"
						fullWidth
						value={sectionForm.order}
						onChange={handleTextChange}
						name="order"
						type="number"
					/>
				</Grid>
				<Grid item xs={12}>
					<Select
						fullWidth
						value={sectionForm.sectionType}
						onChange={handleSectionTypeChange}
					>
						<MenuItem value="class">Class</MenuItem>
						<MenuItem value="student">Student</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Section Description"
						fullWidth
						value={sectionForm.description}
						onChange={handleTextChange}
						name="description"
					/>
					{isComplete && (
						<Typography sx={{ mt: 1, color: 'green' }}>
							Changes Saved
						</Typography>
					)}
				</Grid>
				<Grid item xs={12}>
					<Button
						variant="outlined"
						fullWidth
						sx={{ padding: 1, mt: 1 }}
						onClick={handleSave}
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default NewSection;
