import { createContext, useState, useEffect, useCallback } from "react";
import { signInWithPopup, UserCredential, User as FirebaseUser } from "firebase/auth";
import { auth, provider } from "../firebase";
import useAddDoc from "../hooks/useAddDoc";
import useGetDocs from "../hooks/useGetDoc";
import { StaffInterface } from "../interfaces/interfaces";


interface AuthContextInterface {
  loading: boolean;
  currentAuthUser: FirebaseUser | null;
  logout: () => void;
  signInWithGoogle: () => void;
}

const initialState = () => ({
  currentAuthUser: null,
  logout: () => {},
  loading: false,
  signInWithGoogle: () => {},
});

const AuthContext = createContext<AuthContextInterface>(initialState());
export { AuthContext };

type Props = {
  children: JSX.Element;
};

const AuthProvider = ({ children }: Props) => {
	const [loading, setLoading] = useState(true);
	const [currentAuthUser, setCurrentAuthUser] = useState<FirebaseUser | null>(
		null
	);
	const { sendRequest: addDoc } = useAddDoc();
	const { sendRequest: getDocs } = useGetDocs();

	/**
	 * SignInWithGoogle
   * 
	 * If the user email ends in thegatheringplacek12.org or if it user the user signed out 
   * then get the staffs documents from the database labeled "staff"
   *
   * However if the user isnt found then create a new document in the database with the users 
   * information.
   * 
	 *
	 */
	const signInWithGoogle = useCallback(() => {
		setLoading(true);
		signInWithPopup(auth, provider).then(async ({ user }: UserCredential) => {
			if (!user || !user.email) {
				auth.signOut();
				setLoading(false);
				return;
			}
			user.email.split('@')[1] === 'thegatheringplacek12.org' || auth.signOut();
			const allStaff = await getDocs<StaffInterface>({ col: 'staff' });
			const foundCurrentUser = allStaff.find(
				(staffMember) => staffMember.email === user.email
			);
			if (!foundCurrentUser) {
				setCurrentAuthUser(null);
				await addDoc({
					col: 'staff',
					data: {
						email: user.email,
						firstName: user.displayName,
						lastName: '',
						permissions: [],
					},
				});
				setCurrentAuthUser(user);
			}
		});
	}, [addDoc, getDocs]);

	const logout = useCallback(() => {
		setCurrentAuthUser(null);
		return auth.signOut();
	}, []);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(
			async (user: FirebaseUser | null) => {
				if (user) {
					setCurrentAuthUser(user);
				} else {
					setCurrentAuthUser(null);
				}
				setLoading(false);
			}
		);

		return unsubscribe;
	}, []);

	useEffect(() => {
		if (currentAuthUser) {
			setLoading(false);
		}
	}, [currentAuthUser]);

	const value = {
		currentAuthUser,
		signInWithGoogle,
		logout,
		loading,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
