import React, { SyntheticEvent, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { entriesResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import {ImageProps} from "../../interfaces/interfaces"

type Props = {
  open: boolean;
  setOpen: (prevValue: boolean) => void;
  caption: string;
  col: string;
  id: string;
  existingImages: ImageProps[];
  index: number;
  imageURL: string;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

const ImageCaptionEditDialog = ({
  open,
  setOpen,
  caption,
  col,
  id,
  existingImages,
  index,
  imageURL,
}: Props) => {
  const [value, setValue] = useState(caption);
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setEntriesReset = useSetRecoilState(entriesResetAtom);

  const handleChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setValue(formState.value);
  };

  const handleClose = () => {
    setValue(caption);
    setOpen(false)
  }

  const handleSave = async () => {
    const tempArray = [...existingImages];
    const imageObj = {...tempArray[index]}
    imageObj.caption = value;
    tempArray.splice(index, 1, imageObj)
    await updateDoc({ col: col, id: id, data: { images: tempArray } });
    setOpen(false);
    setEntriesReset((pV) => !pV);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: 44 }}>Edit Caption</DialogTitle>
      <DialogContent>
        {imageURL && (
          <Box sx={{ maxWidth: "80VW", textAlign: "center" }}>
            <img
              src={imageURL}
              alt="uploaded for user"
              style={{ maxWidth: "100%", maxHeight: "70VH" }}
            />
          </Box>
        )}
        <TextField
          autoFocus
          name="caption"
          type="text"
          required
          fullWidth
          multiline
          value={value}
          variant="outlined"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ImageCaptionEditDialog);
