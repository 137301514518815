import { Link } from "react-router-dom";
import { StaffInterface } from "../../interfaces/interfaces";
import { PERMISSION } from "../../libraries/objects";

type Props = {
  loggedInStaff: StaffInterface;
};

function WebLinks({ loggedInStaff }: Props) {
  return (
    <>
      {loggedInStaff.permissions?.includes(PERMISSION.EDIT_STAFF) && (
        <Link className="webLink" to="/staff">
          Staff
        </Link>
      )}
      {loggedInStaff.permissions?.includes(PERMISSION.EDIT_SETTINGS) && (
        <Link className="webLink" to="/settings">
          Settings
        </Link>
      )}

      <Link className="webLink" to="/student">
        Student Sections
      </Link>
      <Link className="webLink" to="/class">
        Class Sections
      </Link>
      <Link className="webLink" to="/print">
        Print
      </Link>
    </>
  );
}

export default WebLinks;
