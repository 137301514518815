import NewRosterOverride from "./NewRosterOverride";
import RosterOverridesList from "./RosterOverridesList";

export default function RosterOverrideContainer() {
  return (
    <>
      <NewRosterOverride />
      <RosterOverridesList />
    </>
  );
}
