import { FC } from "react";
import { Container, Box } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import PrintContainer from "../components/Print/PrintContainer";

const PrintPage: FC = () => {
  return (
    <>
      <Box className="no-print">
        <Navbar />
      </Box>
        <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8 }}>
          <PrintContainer />
        </Container>
    </>
  );
};

export default PrintPage;
