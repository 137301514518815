import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { gradesAtom, objectivesAtom } from "../recoil/atoms";
import { Box, Button } from "@mui/material";
import useUpdateDoc from "../hooks/useUpdateDoc";

enum Grade {
  kinder = "KbWFTsZGPTJB4KbhIB3T",
  first = "UcJJTfEmIdN5chIXZu26",
  second = "TkJXL14bXFuWGA68cGm7",
  third = "UNHEGFdljECNrMH14z4x",
  fourth = "UWxhpv6CPFfpluWpz7Sh",
  fifth = "8ynWdJ22baVdudHQ0RtL",
  sixth = "dpmPGOXlFWA5Qsjxppok",
  seventh = "mXJYdm5lng2V0WDpKqYh",
  mixed = "zJzydMO2Y79FqS3F5mVj",
}

const UpdateObjectivesWithGrades = () => {
  const grades = useRecoilValue(gradesAtom);
  const objectives = useRecoilValue(objectivesAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const handleClick = () => {
    objectives.forEach((objective) => {
      const gradeIds = [...objective.gradeIds];
      if (objective.grades && objective.grades.KINDERGARTEN) {
        if (!gradeIds.includes(Grade.kinder)) {
          gradeIds.push(Grade.kinder);
        }
        if (!gradeIds.includes(Grade.mixed)) {
          gradeIds.push(Grade.mixed);
        }
      }
      if (objective.grades && objective.grades.LOWER_ELEMENTARY) {
        if (!gradeIds.includes(Grade.first)) {
          gradeIds.push(Grade.first);
        }
        if (!gradeIds.includes(Grade.second)) {
          gradeIds.push(Grade.second);
        }
        if (!gradeIds.includes(Grade.mixed)) {
          gradeIds.push(Grade.mixed);
        }
      }
      if (objective.grades && objective.grades.UPPER_ELEMENTARY) {
        if (!gradeIds.includes(Grade.third)) {
          gradeIds.push(Grade.third);
        }
        if (!gradeIds.includes(Grade.fourth)) {
          gradeIds.push(Grade.fourth);
        }
        if (!gradeIds.includes(Grade.fifth)) {
          gradeIds.push(Grade.fifth);
        }
        if (!gradeIds.includes(Grade.mixed)) {
          gradeIds.push(Grade.mixed);
        }
      }
      //updateDoc({ col: "objectives", data: { gradeIds: gradeIds }, id: objective.id });
    });
  };

  return (
    <Box>
      <Button onClick={handleClick}>Update Grades</Button>
    </Box>
  );
};

export default UpdateObjectivesWithGrades;
