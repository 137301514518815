import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ImagePreview from "./ImagePreview";
import { createImageColumns } from "../../libraries/functions";
import { ImageProps, ImageDetails } from "../../interfaces/interfaces";

type Props = {
  images: ImageProps[];
  col: string;
  id: string;
  forPrint: boolean;
};

const ImagesDisplay = ({ images, col, id, forPrint }: Props) => {
  const [imageList, setImageList] = useState<ImageDetails[]>([]);
  const [imageListWithColumns, setImageListWithColumns] = useState<ImageDetails[]>([]);

  useEffect(() => {
    setImageList([]);
    if (!images) return;
    const getImages = async () => {
      const imageDetails: ImageDetails[] = [];
      const storage = getStorage();
      await Promise.all(
        images.map(async (file) => {
          let url = await getDownloadURL(ref(storage, file.fileName));
          url = url + "_2000x2000";
          imageDetails.push({
            url: url,
            caption: file.caption,
            dimensions: file.dimensions,
            fileName: file.fileName,
            columns: { xs: 12, sm: 12, md: 12 },
          });
        })
      );
      //Why do you have to sort the image details ? 
      const sortedImageList = imageDetails.sort((a, b) => (a.fileName > b.fileName ? 1 : -1));
      setImageList(sortedImageList);
    };
    getImages();
  }, [images]);

  useEffect(() => {
    setImageListWithColumns([]);
    if (imageList.length === 0) return;
    const tempArray = createImageColumns(imageList);
    setImageListWithColumns(tempArray);
  }, [imageList]);


  return (
    <>
      <Grid container spacing={2} columns={12}>
        {imageListWithColumns.map((image, index) => (
          <ImagePreview
            image={image}
            key={image.url}
            col={col}
            id={id}
            images={images}
            index={index}
            forPrint={forPrint}
          />
        ))}
      </Grid>
    </>
  );
};

export default React.memo(ImagesDisplay);
