import { useState } from "react";
import { Paper, Grid, Typography, Button, Box, Chip } from "@mui/material";
import { ObjectiveInterface } from "../../interfaces/interfaces";
import EditObjectiveDialog from "./EditObjectiveDialog";
import { gradesAtom, objectivesResetAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import DeleteDialog from "../Shared/DeleteDialog";

type Props = {
  objective: ObjectiveInterface;
};

const message = "Are you sure you want to delete this Objective? This action can not be undone.";

const columns = {
  left: { xs: 16, sm: 16, md: 10, lg: 11 },
  middle: { xs: 8, sm: 8, md: 2, lg: 2 },
  right: { xs: 8, sm: 8, md: 4, lg: 3 },
};

function Objective({ objective }: Props) {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const grades = useRecoilValue(gradesAtom);
  const setObjectivesReset = useSetRecoilState(objectivesResetAtom);
  const handleEditClick = () => {
    setEditOpen(true);
  };
  const handleDeleteClick = () => {
    setDeleteOpen(true);
  };

  const checkColor = (objective: ObjectiveInterface, gradeId: string) => {
    if (objective.gradeIds.includes(gradeId)) {
      return "success";
    } else {
      return "default";
    }
  };
  return (
    <>
      <Paper sx={{ padding: 2, mt: 1, mb: 1 }}>
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            xs={columns.left.xs}
            sm={columns.left.sm}
            md={columns.left.md}
            lg={columns.left.lg}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Typography variant="body2">{objective.label}</Typography>

              <Box>
                {grades.map((grade) => (
                  <Chip
                    sx={{ mr: 1, mt: 1 }}
                    label={grade.name}
                    key={grade.id}
                    color={checkColor(objective, grade.id)}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={columns.middle.xs}
            sm={columns.middle.sm}
            md={columns.middle.md}
            lg={columns.middle.lg}
            sx={{ justifyContent: "flex-start", display: "flex", alignItems: "center" }}
          >
            <Typography variant="body1">
              <b>Order: </b>
              {objective.order}
            </Typography>
          </Grid>
          <Grid
            item
            xs={columns.right.xs}
            sm={columns.right.sm}
            md={columns.right.md}
            lg={columns.right.lg}
            sx={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}
          >
            <Button sx={{ mr: 2 }} variant="contained" onClick={handleEditClick}>
              Edit
            </Button>
            <Button color="error" variant="contained" onClick={handleDeleteClick}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <EditObjectiveDialog open={editOpen} setOpen={setEditOpen} objective={objective} />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message={message}
        collection="objectives"
        id={objective.id}
        setReset={setObjectivesReset}
      />
    </>
  );
}

export default Objective;
