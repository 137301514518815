import { useEffect, useContext } from "react";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import useGetDocs from "./useGetDocs";
import {
  staffAtom,
  loggedInStaffAtom,
  periodsAtom,
  staffFromAPIAtom,
  entriesAtom,
  sectionsResetAtom,
  sectionsAtom,
  entriesResetAtom,
  objectivesAtom,
  objectivesResetAtom,
  periodsResetAtom,
  selectedPeriodIdAtom,
  attendanceRecordsAtom,
  rosterOverridesAtom,
  rosterOverridesResetAtom,
  availableHomeroomsAtom,
  selectedHomeroomIdAtom,
  rawMClassRecordsAtom,
  selectedStudentIdAtom,
  studentsObjAtom,
  promotionIndicatorAtom,
  promotionIndicatorResetAtom,
} from "../recoil/atoms";
import {
  StaffInterface,
  PeriodInterface,
  SectionInterface,
  EntryInterface,
  ObjectiveInterface,
  AttendanceRecordInterface,
  RosterOverrideInterface,
  MClassRecordInterface,
  StandardRecordInterface,
} from "../interfaces/interfaces";
import {
  parseStaffResponse,
  parsePeriodResponse,
  parseSectionResponse,
  parseEntryResponse,
  parseObjectiveResponse,
  parseAttendanceRecordResponse,
  parseRosterOverrideResponse,
  parseMClassRecordResponse,
  parseStandardRecordResponse,
  parseStudentCourseScoresResponse,
  parseStudentStandardScoresResponse,
  parsePromotionIndicatorRecordResponse,
} from "../libraries/parsers";
import { AuthContext } from "../providers/AuthProvider";
import { getIdOfFirstItem } from "../libraries/functions";
import { standardsAtom, standardsResetAtom } from "../recoil/standardsAtoms";
import {
  PromotionIndicatorRecord,
  StudentCourseScoreRecord,
  StudentStandardScoreRecord,
} from "../types/types";
import {
  rawStudentCourseScoresAtom,
  rawStudentStandardScoresAtom,
} from "../recoil/studentScoresAtoms";

type FIREBASE_ID = string;

const useBootstrapEffect = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const { currentAuthUser } = useContext(AuthContext);
  const staff = useRecoilValue(staffAtom);
  const staffFromAPI = useRecoilValue(staffFromAPIAtom);
  const setStaff = useSetRecoilState<StaffInterface[]>(staffAtom);
  const setEntries = useSetRecoilState<EntryInterface[] | null>(entriesAtom);
  const setSections = useSetRecoilState<SectionInterface[] | null>(sectionsAtom);
  const setPeriods = useSetRecoilState<PeriodInterface[]>(periodsAtom);
  const setStandards = useSetRecoilState<StandardRecordInterface[]>(standardsAtom);
  const setStudentCourseScores = useSetRecoilState<StudentCourseScoreRecord[]>(
    rawStudentCourseScoresAtom
  );
  const setStudentStandardScores = useSetRecoilState<StudentStandardScoreRecord[]>(
    rawStudentStandardScoresAtom
  );
  const setLoggedInStaff = useSetRecoilState<StaffInterface | null>(loggedInStaffAtom);
  const setRawMClassRecords = useSetRecoilState<MClassRecordInterface[]>(rawMClassRecordsAtom);
  const setObjectives = useSetRecoilState(objectivesAtom);
  const setSelectedHomeroomId = useSetRecoilState<string>(selectedHomeroomIdAtom);
  const setRosterOverrides = useSetRecoilState<RosterOverrideInterface[] | null>(
    rosterOverridesAtom
  );
  const setAttendanceRecords = useSetRecoilState<AttendanceRecordInterface[] | null>(
    attendanceRecordsAtom
  );
  const setPromotionIndicator =
    useSetRecoilState<PromotionIndicatorRecord[]>(promotionIndicatorAtom);
  const [selectedPeriodId, setSelectedPeriodId] = useRecoilState<FIREBASE_ID>(selectedPeriodIdAtom);
  const entriesReset = useRecoilValue(entriesResetAtom);
  const sectionsReset = useRecoilValue(sectionsResetAtom);
  const objectivesReset = useRecoilValue(objectivesResetAtom);
  const periodsReset = useRecoilValue(periodsResetAtom);
  const rosterOverridesReset = useRecoilValue(rosterOverridesResetAtom);
  const standardsReset = useRecoilValue(standardsResetAtom);
  const availableHomerooms = useRecoilValue(availableHomeroomsAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const studentsObj = useRecoilValue(studentsObjAtom);
  const promotionIndicatorReset = useRecoilValue(promotionIndicatorResetAtom);

  useEffect(() => {
    const getStaff = async () => {
      const response = await getDocs<StaffInterface>({ col: "staff" });
      if (response) {
        setStaff(parseStaffResponse(response));
      }
    };
    getStaff();
  }, [setStaff, getDocs, currentAuthUser]);

  useEffect(() => {
    const getPeriods = async () => {
      const response = await getDocs<PeriodInterface>({ col: "periods" });
      if (response) {
        setPeriods(parsePeriodResponse(response));
        if (!Boolean(selectedPeriodId)) {
          setSelectedPeriodId(getIdOfFirstItem(response));
        }
      }
    };
    getPeriods();
  }, [setPeriods, getDocs, periodsReset, setSelectedPeriodId, selectedPeriodId]);

  useEffect(() => {
    if (!currentAuthUser || staff.length === 0 || staffFromAPI.length === 0) return;
    const filteredStaff = staff.filter(
      (staffMember) => staffMember.email === currentAuthUser.email
    );
    const [filteredStaffFromAPI] = staffFromAPI.filter(
      (staffMember) => staffMember.email === currentAuthUser.email
    );
    if (filteredStaff.length === 1) {
      setLoggedInStaff({ ...filteredStaff[0], homerooms: filteredStaffFromAPI.homerooms });
    } else {
      //generate message
      console.error(
        "[Hook] useBootstrapEffect - filtered Staff, no DB user found or multiple DB users found",
        currentAuthUser.email,
        filteredStaff.length
      );
    }
  }, [currentAuthUser, staff, setLoggedInStaff, staffFromAPI]);

  useEffect(() => {
    const getEntries = async () => {
      if (!selectedStudentId) return;
      const response = await getDocs<EntryInterface>({
        col: "entries",
        config: { where: ["studentId", "==", selectedStudentId] },
      });
      if (response) {
        setEntries(parseEntryResponse(response));
      }
    };
    getEntries();
  }, [setEntries, getDocs, entriesReset, selectedStudentId]);

  useEffect(() => {
    const getSections = async () => {
      const response = await getDocs<SectionInterface>({
        col: "sections",
        config: { orderBy: ["order"] },
      });
      if (response) {
        setSections(parseSectionResponse(response));
      }
    };
    getSections();
  }, [setSections, getDocs, sectionsReset]);

  useEffect(() => {
    const getObjectives = async () => {
      const response = await getDocs<ObjectiveInterface>({
        col: "objectives",
        config: { orderBy: ["order"] },
      });
      if (response) {
        setObjectives(parseObjectiveResponse(response));
      }
    };
    getObjectives();
  }, [setObjectives, getDocs, objectivesReset]);

  useEffect(() => {
    const getAttendanceRecords = async () => {
      if (!selectedStudentId || !studentsObj || !studentsObj[selectedStudentId]) return;
      const response = await getDocs<AttendanceRecordInterface>({
        col: "attendanceRecords",
        config: { where: ["SID", "==", studentsObj[selectedStudentId].SID] },
      });
      if (response) {
        setAttendanceRecords(parseAttendanceRecordResponse(response));
      }
    };
    getAttendanceRecords();
  }, [setAttendanceRecords, getDocs, selectedStudentId, studentsObj]);

  useEffect(() => {
    const getMClassRecords = async () => {
      if (!selectedStudentId || !studentsObj || !studentsObj[selectedStudentId]) return;
      const SID = studentsObj[selectedStudentId].SID.replace(/\b0+/g, "");
      const arrayOfSID: string[] = [studentsObj[selectedStudentId].SID, SID];
      const start = Date.now();
      const response = await getDocs<MClassRecordInterface>({
        col: "mClassRecords",
        config: { where: ["SID", "in", arrayOfSID] },
      });
      if (response) {
        setRawMClassRecords(parseMClassRecordResponse(response));
      }
      const end = Date.now();
    };
    getMClassRecords();
  }, [setRawMClassRecords, getDocs, selectedStudentId, studentsObj]);

  useEffect(() => {
    const getStandardsRecords = async () => {
      const start = Date.now();
      const response = await getDocs<StandardRecordInterface>({
        col: "standards",
      });
      if (response) {
        setStandards(parseStandardRecordResponse(response));
      }
      const end = Date.now();
    };
    getStandardsRecords();
  }, [setStandards, getDocs, standardsReset]);

  useEffect(() => {
    const getStudentCourseScores = async () => {
      const response = await getDocs<StudentCourseScoreRecord>({
        col: "studentCourseScores",
      });
      if (response) {
        setStudentCourseScores(parseStudentCourseScoresResponse(response));
      }
    };
    getStudentCourseScores();
  }, [setStudentCourseScores, getDocs]);

  useEffect(() => {
    const getStudentStandardScores = async () => {
      if (!selectedStudentId || !studentsObj || !studentsObj[selectedStudentId]) return;
      const SID = studentsObj[selectedStudentId].SID.replace(/\b0+/g, "");
      const start = Date.now();
      const response = await getDocs<StudentStandardScoreRecord>({
        col: "studentStandardScores",
        config: { where: ["SID", "==", SID] },
      });
      if (response) {
        setStudentStandardScores(parseStudentStandardScoresResponse(response));
      }
      const end = Date.now();
    };
    getStudentStandardScores();
  }, [setStudentStandardScores, getDocs, selectedStudentId, studentsObj]);

  useEffect(() => {
    const getRosterOverrides = async () => {
      const response = await getDocs<RosterOverrideInterface>({
        col: "rosterOverrides",
      });
      if (response) {
        setRosterOverrides(parseRosterOverrideResponse(response));
      }
    };
    getRosterOverrides();
  }, [setRosterOverrides, getDocs, rosterOverridesReset]);

  useEffect(() => {
    if (!availableHomerooms || selectedHomeroomId) return;
    const firstAvailableId = getIdOfFirstItem(availableHomerooms);
    setSelectedHomeroomId(firstAvailableId);
  }, [availableHomerooms, setSelectedHomeroomId, selectedHomeroomId]);

  useEffect(() => {
    const getPromotionIndicator = async () => {
      const response = await getDocs<PromotionIndicatorRecord>({
        col: "promotionIndicator",
      });
      if (response) {
        setPromotionIndicator(parsePromotionIndicatorRecordResponse(response));
      }
    };
    getPromotionIndicator();
  }, [setPromotionIndicator, getDocs, promotionIndicatorReset]);
};

export default useBootstrapEffect;
