import { atom } from "recoil";
import { StandardRecordInterface } from "../interfaces/interfaces";

type FIREBASE_ID = string;

export const standardsAtom = atom<StandardRecordInterface[]>({
  key: "standards",
  default: [],
});

export const standardsResetAtom = atom({
  key: "standardsReset",
  default: false,
});
