import { ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";
import { theme } from "./Theme";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import AppBootstrap from "./AppBootstrap";
import SyncProvider from "./providers/SyncProvider";
import AuthProvider from "./providers/AuthProvider";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2ebcf4d97fbc4e2e9bd25672dbcd7750@o1074222.ingest.sentry.io/4504222195056640",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <SyncProvider>
          <ThemeProvider theme={theme}>
            <AppBootstrap />
          </ThemeProvider>
        </SyncProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
