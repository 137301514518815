import Button from "@mui/material/Button";

type Props = {
  setDialogOpen: (newValue: boolean) => void;
};

export default function NewPeriodButton({ setDialogOpen }: Props) {
  const handleOpen = () => {
    setDialogOpen(true);
  };
  return (
    <Button
      variant="contained"
      fullWidth
      color="primary"
      onClick={handleOpen}
      sx={{ fontSize: 20, mt: 4, padding: 1, mb: 1 }}
    >
      New Narrative Period
    </Button>
  );
}
