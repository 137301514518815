import { Typography, Box, Button, Grid, Divider } from '@mui/material';
import {
	ObjectiveInterface,
	SectionInterface,
} from '../../interfaces/interfaces';
import NewObjective from './NewObjective';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeNewObjectiveIdAtom, objectivesAtom } from '../../recoil/atoms';
import ObjectivesList from './ObjectivesList';
import useWindowDimensions from '../../hooks/useWindowDimensions';

type Props = {
	section: SectionInterface;
};

const filterObjectives = (
	objectives: ObjectiveInterface[] | null,
	sectionId: string
) => {
	const results =
		objectives?.filter((objective) => objective.sectionId === sectionId) ??
		null;
	if (results) {
		return results.sort((a, b) => (a.order > b.order ? 1 : -1));
	} else {
		return null;
	}
};

function ObjectivesSection({ section }: Props) {
	const [activeNewObjectiveId, setActiveNewObjectiveId] = useRecoilState(
		activeNewObjectiveIdAtom
	);
	const open = Boolean(activeNewObjectiveId);
	const objectives = useRecoilValue(objectivesAtom);
	const filteredObjectives = filterObjectives(objectives, section.id);
	const { width } = useWindowDimensions();
	const mobileWidth = width < 900;

	const handleOpenNewObjective = () => {
		if (open) {
			setActiveNewObjectiveId('');
		} else {
			setActiveNewObjectiveId(section.id);
		}
	};

	if (mobileWidth) {
		return (
			<Box sx={{ mt: 2 }}>
				<Box
					sx={{
						textAlign: 'center',
						mb: 2,
					}}
				>
					<Typography variant="h5">{section.label}</Typography>
					<Button
						variant="contained"
						onClick={handleOpenNewObjective}
						fullWidth
						sx={{ mt: 1 }}
					>
						{open ? 'Close New Objective Form' : 'Create New Objective'}
					</Button>
				</Box>

				{activeNewObjectiveId === section.id && (
					<NewObjective sectionId={section.id} />
				)}
				<Box>
					<ObjectivesList objectives={filteredObjectives} />
				</Box>
				<Divider sx={{ mt: 2 }} />
			</Box>
		);
	} else {
		return (
			<Box sx={{ mt: 2 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="h5">{section.label}</Typography>
					<Button variant="contained" onClick={handleOpenNewObjective}>
						{open ? 'Close New Objective Form' : 'Create New Objective'}
					</Button>
				</Box>
				{activeNewObjectiveId === section.id && (
					<NewObjective sectionId={section.id} />
				)}
				<Box>
					<ObjectivesList objectives={filteredObjectives} />
				</Box>
			</Box>
		);
	}
}

export default ObjectivesSection;
