import { createContext, useState, useCallback } from "react";
import useUpdateDoc from "../hooks/useUpdateDoc";
import { EntryInterface } from "../interfaces/interfaces";
import useInterval from "../hooks/useInterval";

type ISO_DATE = string;

interface SyncContextInterface {
  isSyncing: boolean;
  setPendingEntry: (entry: EntryInterface) => void;
}

const initialState = () => ({
  isSyncing: false,
  setPendingEntry: () => console.error("[Provider] SyncProvider not initialized"),
});

const SyncContext = createContext<SyncContextInterface>(initialState());
export { SyncContext };

type Props = {
  children: JSX.Element;
};

const SYNC_INTERVAL_MS = 3000;

const SyncProvider = ({ children }: Props) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastSync, setLastSync] = useState<ISO_DATE>(new Date().toISOString());
  const [pendingEntries, setPendingEntries] = useState<EntryInterface[]>([]);

  const { sendRequest: updateDoc } = useUpdateDoc();

  const setPendingEntry = useCallback(
    (entry: EntryInterface) => {
      const tempPendingEntries = [...pendingEntries];
      if (tempPendingEntries.length > 0) {
        tempPendingEntries.forEach((pendingEntry, index) => {
          if (entry.id === pendingEntry.id) {
            tempPendingEntries[index] = entry;
          } else {
            tempPendingEntries.push(entry);
          }
        });
      } else {
        tempPendingEntries.push(entry);
      }
      setPendingEntries(tempPendingEntries);
    },
    [pendingEntries]
  );

  useInterval(async () => {
    if (isSyncing || pendingEntries.length === 0) {
      return;
    }
    const requestPromises: Array<Promise<string | null>> = [];
    setIsSyncing(true);
    pendingEntries.forEach((pendingEntry) => {
      requestPromises.push(
        updateDoc({
          col: "entries",
          id: pendingEntry.id,
          data: { html: pendingEntry.html },
        })
      );
    });
    setPendingEntries([]);
    await Promise.all(requestPromises);

    setLastSync(new Date().toISOString());
    setIsSyncing(false);
  }, SYNC_INTERVAL_MS);

  const value = {
    isSyncing,
    setPendingEntry,
    lastSync,
  };

  return <SyncContext.Provider value={value}>{children}</SyncContext.Provider>;
};

export default SyncProvider;
