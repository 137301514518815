import { EntryInterface } from "../../interfaces/interfaces";
import ImagesDisplay from "../ImageUpload/ImagesDisplay";

type Props = {
  entry: EntryInterface | undefined;
};

function EntryImages({ entry }: Props) {
  return <>{entry && <ImagesDisplay images={entry.images} col="" id="" forPrint={true} />}</>;
}

export default EntryImages;
