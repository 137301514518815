import { useRef, useEffect, useState } from "react";
import { Box, Typography, Button, Switch, FormControlLabel } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  classSectionsAtom,
  studentSectionsAtom,
  entriesAtom,
  selectedHomeroomIdAtom,
  selectedStudentIdAtom,
  selectedPeriodIdAtom,
  objectivesAtom,
  availableStudentsAtom,
  entriesResetAtom,
} from "../../recoil/atoms";
import SelectStudent from "../Shared/SelectStudent";
import { useReactToPrint } from "react-to-print";
import PrintTable from "./PrintTable";

function PrintContainer() {
  const componentRef = useRef(null);
  const setEntriesReset = useSetRecoilState(entriesResetAtom);
  const classSections = useRecoilValue(classSectionsAtom);
  const studentSections = useRecoilValue(studentSectionsAtom);
  const entries = useRecoilValue(entriesAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const objectives = useRecoilValue(objectivesAtom);
  const students = useRecoilValue(availableStudentsAtom);
  const [showImages, setShowImages] = useState(true);

  const handleShowImageChange = () => {
    setShowImages((pV) => !pV);
  };

  useEffect(() => {
    setEntriesReset((pV) => !pV);
  }, [setEntriesReset]);

  const loading = Boolean(
    !classSections ||
      !studentSections ||
      !entries ||
      !selectedHomeroomId ||
      !selectedPeriodId ||
      !selectedStudentId ||
      !objectives ||
      !students
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pt: 3,
        }}
      >
        <Box>
          <Typography variant="h2">Print Narratives</Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={<Switch checked={showImages} onChange={handleShowImageChange} />}
            label="Show Images"
          />
        </Box>
        <SelectStudent />
        <Button onClick={handlePrint} variant="contained">
          Print
        </Button>
      </Box>
      {!loading && (
        <Box
          ref={componentRef}
          sx={{ width: "8.5in", margin: "0px auto", backgroundColor: "#ffffff", padding: 4 }}
        >
          <PrintTable
            classSections={classSections}
            studentSections={studentSections}
            showImages={showImages}
          />
        </Box>
      )}
    </>
  );
}

export default PrintContainer;
