import { useEffect, useMemo, useContext } from "react";
import RichTextEditorContainer from "../RichTextEditor/RichTextEditorContainer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Typography } from "@mui/material";
import {
  entriesAtom,
  entriesResetAtom,
  selectedPeriodIdAtom,
  selectedHomeroomIdAtom,
  classSectionsAtom,
} from "../../recoil/atoms";
import useAddDoc from "../../hooks/useAddDoc";
import { AuthContext } from "../../providers/AuthProvider";

type EMAIL = string;
type FIREBASE_ID = string;

type StartingForm = {
  sectionId: string | null;
  sectionType: string;
  periodId: FIREBASE_ID;
  authorEmail: EMAIL;
  html: string;
  homeroomId: FIREBASE_ID;
  studentId: FIREBASE_ID;
  id: FIREBASE_ID;
  images: [];
  objectives: [];
};

const OverviewsContainer = () => {
  const entries = useRecoilValue(entriesAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const setEntriesReset = useSetRecoilState(entriesResetAtom);
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const classSections = useRecoilValue(classSectionsAtom);
  const { currentAuthUser } = useContext(AuthContext);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);

  const startingForm = useMemo<StartingForm>(
    () => ({
      sectionId: null,
      sectionType: "class",
      periodId: selectedPeriodId,
      authorEmail: currentAuthUser?.email ?? "",
      html: "",
      homeroomId: selectedHomeroomId,
      studentId: "none",
      id: "",
      images: [],
      objectives: [],
    }),
    [selectedPeriodId, currentAuthUser, selectedHomeroomId]
  );

  //convert into a hook
  useEffect(() => {
    if (!entries || !classSections) return;
    const checkIfSectionsExist = async () => {
      const currentEntries = entries.filter((entry) => entry.studentId === "none");
      const requestPromises: Array<Promise<string | null>> = [];
      let reset = false;

      classSections.forEach((section) => {
        const entryExists = currentEntries.filter(
          (entry) =>
            entry.sectionId === section.id &&
            entry.periodId === selectedPeriodId &&
            entry.homeroomId === selectedHomeroomId
        );
        if (entryExists.length === 0) {
          const entryForm = { ...startingForm, sectionId: section.id };
          requestPromises.push(addDoc({ col: "entries", data: entryForm }));
          reset = true;
        }
      });
      await Promise.all(requestPromises);
      if (reset) {
        setEntriesReset((pV) => !pV);
      }
    };
    checkIfSectionsExist();
  }, [
    entries,
    addDoc,
    startingForm,
    setEntriesReset,
    classSections,
    selectedPeriodId,
    selectedHomeroomId,
  ]);
  return (
    <>
      {selectedHomeroomId ? (
        <>
          <Typography variant="h2">Class Sections</Typography>
          <Typography variant="body2">
            The sections below will be printed at the top of all narratives for this class. Any
            section left blank will not be printed on the narrative. For example, if Special
            Education is not filled it, there will not be a special education section on the
            narrative.
          </Typography>
          {classSections &&
            classSections.map((section) => (
              <RichTextEditorContainer key={section.id} section={section} sectionType="class" />
            ))}
        </>
      ) : (
        <Typography variant="h5" textAlign="center">You do not currently have any classes assigned to you for narratives. Please contact Ryan York on Slack to resolve this issue.</Typography>
      )}
    </>
  );
};

export default OverviewsContainer;
