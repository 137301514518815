import { useRecoilValue } from "recoil";
import { EntryInterface, SectionInterface } from "../../interfaces/interfaces";
import {
  objectivesAtom,
  selectedHomeroomIdAtom,
  homeroomsAtom,
  gradesAtom,
} from "../../recoil/atoms";
import { Typography } from "@mui/material";
import SetObjectiveCard from "./SetObjectiveCard";

type Props = {
  entry: EntryInterface;
  section: SectionInterface;
};

function SetObjectivesContainer({ section, entry }: Props) {
  const objectives = useRecoilValue(objectivesAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const homerooms = useRecoilValue(homeroomsAtom);
  const homeroom = homerooms.find((h) => h.id === selectedHomeroomId);
  const filteredObjectives = objectives?.filter((objective) => {
    if (!homeroom) return false;
    return objective.gradeIds.includes(homeroom.gradeId) && section.id === objective.sectionId
      ? true
      : false;
  });

  return (
    <>
      <Typography variant="body1">
        Any objective not identified as a Glow or a Grow will not be printed on the narrative.
      </Typography>
      {filteredObjectives &&
        filteredObjectives.map((objective) => (
          <SetObjectiveCard key={objective.id} objective={objective} entry={entry} />
        ))}
    </>
  );
}

export default SetObjectivesContainer;
