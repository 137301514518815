import { ObjectiveInterface } from "../../interfaces/interfaces";
import Objective from "./Objective";

type Props = {
  objectives: ObjectiveInterface[] | null;
};

function ObjectivesList({ objectives }: Props) {
  return (
    <>
      {objectives &&
        objectives.map((objective) => <Objective objective={objective} key={objective.id} />)}
    </>
  );
}

export default ObjectivesList;
