import { PeriodInterface, GradeInterface } from "../interfaces/interfaces";

export enum PERMISSION {
  EDIT_STAFF = "Edit Staff",
  EDIT_ALL_NARRATIVES = "Edit Other People's Narratives",
  EDIT_SETTINGS = "Edit Settings",
  SEE_ALL_ROSTERS = "See All Rosters",
}

export enum SettingsSections {
  PERIODS = "Narrative Periods",
  SECTIONS = "Narrative Sections",
  OBJECTIVES = "Narrative Objectives",
  OVERRIDE = "Roster Override",
}

export const BLANK_PERIOD_FORM: PeriodInterface = {
  label: "",
  dueDate: "",
  id: "",
  isActive: false,
};

export const PERMISSIONS: Array<PERMISSION> = [
  PERMISSION.EDIT_STAFF,
  PERMISSION.EDIT_SETTINGS,
  PERMISSION.SEE_ALL_ROSTERS,
  PERMISSION.EDIT_ALL_NARRATIVES,
];

export const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }],
  [{ direction: "rtl" }], // text direction
  [{ align: [] }],

  ["clean"], // remove formatting button
];

export const PROFICIENCY_LEVELS = {
  kinder: { below: 131, approaching: 141, meets: 151, masters: 161 },
  first: { below: 141, approaching: 151, meets: 161, masters: 171 },
  second: { below: 151, approaching: 161, meets: 171, masters: 181 },
  third: { below: 161, approaching: 171, meets: 181, masters: 191 },
  fourth: { below: 181, approaching: 191, meets: 201, masters: 211 },
  fifth: { below: 191, approaching: 201, meets: 211, masters: 221 },
  sixth: { below: 201, approaching: 211, meets: 221, masters: 231 },
  seventh: { below: 211, approaching: 221, meets: 231, masters: 241 },
};
