import { Box, Container } from "@mui/material";
import Navbar from "../components/Navigation/Navbar";
import UploadAttendanceContainer from "../components/UploadAttendance/UploadAttendanceContainer";

const UploadAttendancePage = () => {
  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Navbar />
        <Container maxWidth="md" sx={{ minHeight: "100vh", pt: 2, mt: 8 }}>
        <UploadAttendanceContainer />
        </Container>
      </Box>
    </>
  );
};

export default UploadAttendancePage;
