import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { SettingsSections } from '../../libraries/objects';
import useWindowDimensions from '../../hooks/useWindowDimensions';

type Props = {
	setActiveSettingSection: (newValue: string) => void;
};

export default function SettingsSideMenu({ setActiveSettingSection }: Props) {
	const { width } = useWindowDimensions();
	const mobileTextAlign = (width < 900) ? "center":"left";
	const mobileOverflowY = (width < 900) ? "scroll":""
	const mobileOverflow = width < 900 ? 'hidden' : '';
	return (
		<Box
			sx={{
				width: '100%',
				overflow: mobileOverflow,
				overflowY: mobileOverflowY,
				height: '10rem',
			}}
		>
			<nav>
				<List>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								setActiveSettingSection(SettingsSections.PERIODS);
							}}
						>
							<ListItemText
								primary="Narrative Periods"
								sx={{
									textAlign: mobileTextAlign,
								}}
							/>
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								setActiveSettingSection(SettingsSections.SECTIONS);
							}}
						>
							<ListItemText
								primary="Narrative Sections"
								sx={{
									textAlign: mobileTextAlign,
								}}
							/>
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								setActiveSettingSection(SettingsSections.OBJECTIVES);
							}}
						>
							<ListItemText
								primary="Narrative Objectives"
								sx={{
									textAlign: mobileTextAlign,
								}}
							/>
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => {
								setActiveSettingSection(SettingsSections.OVERRIDE);
							}}
						>
							<ListItemText
								primary="Roster Override"
								sx={{
									textAlign: mobileTextAlign,
								}}
							/>
						</ListItemButton>
					</ListItem>
					<Divider />
				</List>
			</nav>
		</Box>
	);
}
