import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { entriesResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import {ImageProps} from "../../interfaces/interfaces"

type Props = {
  open: boolean;
  setOpen: (prevValue: boolean) => void;
  col: string;
  id: string;
  images: ImageProps[];
  index: number;
};

export default function ImageDeleteConfirmation({ open, setOpen, col, id, images, index }: Props) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setEntriesReset = useSetRecoilState(entriesResetAtom);
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    const newImages = [...images];
    newImages.splice(index, 1);
    await updateDoc({ col: col, id: id, data: { images: newImages } });
    setOpen(false);
    setEntriesReset((pV) => !pV);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: 44 }}>Delete Image Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this image? This action can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
