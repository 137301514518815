import React, { useState } from "react";
import { Button, Container } from "@mui/material";
import ImageUploadDialog from "./ImageUploadDialog";
import { ImageProps } from "../../interfaces/interfaces";
import ImagesDisplay from "./ImagesDisplay";

type Props = {
  images: ImageProps[];
  col: string;
  id: string;
  forPrint: boolean;
};

const ImagePreviewContainer = ({ images, col, id, forPrint }: Props) => {
  const [open, setOpen] = useState(false);

  const handleNewImageClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Container>
        <ImagesDisplay images={images} col={col} id={id} forPrint={forPrint} />
      </Container>
      <Button sx={{ mt: 2 }} variant="contained" onClick={handleNewImageClick}>
        Add Image
      </Button>
      <ImageUploadDialog open={open} setOpen={setOpen} existingImages={images} col={col} id={id} />
    </>
  );
};

export default React.memo(ImagePreviewContainer);
