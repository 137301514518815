import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import { attendanceRecordsAtom, mClassRecordsAtom } from "../../recoil/atoms";
import useAddDoc from "../../hooks/useAddDoc";
import UploadMClassForm from "./UploadMClassForm";
import { MClassRecordInterface, MClassUploadInterface } from "../../interfaces/interfaces";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

enum Column {
  STUDENT_ID = "Student ID (District ID or School ID)",
  BENCHMARK_PERIOD = "Benchmark Period",
  COMPOSITE_LEVEL = "Composite Level",
  COMPOSITE_SCORE = "Composite Score",
  SCHOOL_YEAR = "School Year",
  FIRST_NAME = "Student First Name",
  LAST_NAME = "Student Last Name",
}

type MClassDictonary = {
  [key in Column]: string;
};

const UploadMClassContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const mClassRecords = useRecoilValue(mClassRecordsAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);

  const searchRecords = (newRecord: MClassRecordInterface) => {
    if (!mClassRecords) return;
    const matchingRecords = mClassRecords.filter(
      (existingRecord) =>
        existingRecord.SID === newRecord.SID &&
        existingRecord.benchmarkPeriod === newRecord.benchmarkPeriod
    );
    return matchingRecords.length > 0 ? true : false;
  };
  const fromWebsite = async (value: MClassDictonary) => {
    const mClassRecord = {
      SID: value[Column.STUDENT_ID] || "",
      benchmarkPeriod: value[Column.BENCHMARK_PERIOD] || "",
      compositeLevel: value[Column.COMPOSITE_LEVEL] || "",
      compositeScore: value[Column.COMPOSITE_SCORE] || "",
      schoolYear: value[Column.SCHOOL_YEAR] || "",
      firstName: value[Column.FIRST_NAME] || "",
      lastName: value[Column.LAST_NAME] || "",
      id: "",
    };
    const found = searchRecords(mClassRecord);
    let resultId: string | null = "";
    if (!found) {
      resultId = await addDoc({ col: "mClassRecords", data: mClassRecord });
    } else {
    }
    return resultId ? resultId : "";
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<MClassDictonary>(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            const resultId = fromWebsite(value);
            promises.push(resultId);
          });
        },
        header: true,
      });
      await Promise.all(promises);
      setLoading(false);
    }
  };

  return (
    <UploadMClassForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
    />
  );
};

export default UploadMClassContainer;
