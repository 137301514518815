import React from "react";
import {
  Button,
  Dialog,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PeriodInterface } from "../../interfaces/interfaces";

type FormState = EventTarget & {
  name: string;
  value: string;
  id: string;
};

type Props = {
  periodForm: PeriodInterface;
  setPeriodForm: (param: (prevValue: PeriodInterface) => PeriodInterface) => void;
  dialogOpen: boolean;
  setDialogOpen: (newValue: boolean) => void;
  saveForm: () => void;
};

export default function PeriodDialog({
  dialogOpen,
  setDialogOpen,
  periodForm,
  setPeriodForm,
  saveForm,
}: Props) {
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleEdit = (event: React.SyntheticEvent) => {
    const formState = event.target as FormState;
    setPeriodForm((pV) => {
      return { ...pV, [formState.id]: formState.value };
    });
  };

  const handleDateEdit = (value: Date | null) => {
    if (!value) return;
    setPeriodForm((pV) => {
      return { ...pV, dueDate: value.toLocaleString("en-US") };
    });
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} maxWidth={"lg"} fullWidth>
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Narrative Period Dialog
      </Typography>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="h5">Narrative Period Name</Typography>
            <TextField
              required
              fullWidth
              id="label"
              type="text"
              variant="outlined"
              onChange={handleEdit}
              value={periodForm.label}
            />
          </Grid>
          <Grid item sm={6}>
            <Typography variant="h5">Narratives Due Date</Typography>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                value={periodForm.dueDate}
                onChange={handleDateEdit}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveForm}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
