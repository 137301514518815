import { Paper, Typography, Box, Button } from '@mui/material';
import { RosterOverrideInterface } from '../../interfaces/interfaces';
import {
	studentsAtom,
	homeroomsAtom,
	periodsAtom,
	rosterOverridesResetAtom,
} from '../../recoil/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useUpdateDoc from '../../hooks/useUpdateDoc';

type Props = {
	rosterOverride: RosterOverrideInterface;
};

function RosterOverrideCard({ rosterOverride }: Props) {
	const students = useRecoilValue(studentsAtom);
	const homerooms = useRecoilValue(homeroomsAtom);
	const periods = useRecoilValue(periodsAtom);
	const setRosterOverridesReset = useSetRecoilState(rosterOverridesResetAtom);
	const { sendRequest: updateDoc } = useUpdateDoc();
	const selectedStudent = students.find(
		(student) => student.id === rosterOverride.studentId
	);
	const selectedHomeroom = homerooms.find(
		(homeroom) => homeroom.id === rosterOverride.homeroomId
	);
	const selectedPeriod = periods.find(
		(period) => period.id === rosterOverride.periodId
	);

	const handleDeleteOverride = async () => {
		await updateDoc({
			col: 'rosterOverrides',
			data: { active: false },
			id: rosterOverride.id,
		});
		setRosterOverridesReset((pV) => !pV);
	};

	return (
		<Paper sx={{ padding: 2, mt: 2 }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography>{`${selectedStudent?.childFirstName} ${selectedStudent?.childLastName} was moved to ${selectedHomeroom?.name} for the ${selectedPeriod?.label} Narrative Period`}</Typography>
				<Button
					sx={{ ml: 2 }}
					variant="contained"
					color="secondary"
					onClick={handleDeleteOverride}
				>
					Delete Override
				</Button>
			</Box>
		</Paper>
	);
}

export default RosterOverrideCard;
