import React, { SyntheticEvent, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { sectionsResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { SectionInterface } from "../../interfaces/interfaces";

type Props = {
  open: boolean;
  setOpen: (prevValue: boolean) => void;
  section: SectionInterface;
};

type FormState = EventTarget & {
  name: string;
  value: string;
};

const EditSectionDialog = ({ open, setOpen, section }: Props) => {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setSectionReset = useSetRecoilState(sectionsResetAtom);
  const [sectionForm, setSectionForm] = useState<SectionInterface>(section);

  const handleTextChange = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSectionForm((pV) => ({ ...pV, [formState.name]: formState.value }));
  };

  const handleSave = async () => {
    await updateDoc({ col: "sections", data: sectionForm, id: section.id });
    setSectionReset((pV) => !pV);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: 44 }}>Edit Section</DialogTitle>
      <DialogContent>
        <TextField
          label="Section Type Name"
          fullWidth
          value={sectionForm.label}
          onChange={handleTextChange}
          name="label"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Section Description"
          fullWidth
          value={sectionForm.description}
          onChange={handleTextChange}
          name="description"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Order"
          fullWidth
          value={sectionForm.order}
          onChange={handleTextChange}
          name="order"
          type="number"
          sx={{ mt: 2 }}
        />
        <Typography>
          Once a section has been created, it can not be moved between the Class Page and the
          Student Page. If needed, please delete this section and create a new on in the
          correct page.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(EditSectionDialog);
