import { useState, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { sectionsResetAtom } from '../../recoil/atoms';
import { Paper, Typography, Button, Grid } from '@mui/material';
import { SectionInterface } from '../../interfaces/interfaces';
import EditSectionDialog from './EditSectionDialog';
import DeleteDialog from '../Shared/DeleteDialog';
import useWindowDimensions from '../../hooks/useWindowDimensions';

type Props = {
	section: SectionInterface;
};

const message =
	'Are you sure you want to delete this Section Type? This action can not be undone.';

function Section({ section }: Props) {
	const [editOpen, setEditOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const setSectionsReset = useSetRecoilState(sectionsResetAtom);
	const { width } = useWindowDimensions();
	const mobile = width > 900;

	const handleEditClick = useCallback(() => {
		setEditOpen(true);
	}, []);

	const handleDeleteClick = useCallback(() => {
		setDeleteOpen(true);
	}, []);

	return mobile ? (
		<>
			<Paper sx={{ padding: 2, mt: 1, mb: 1 }}>
				<Grid container spacing={2}>
					<Grid item sm={6}>
						<Typography variant="h6">{section.label}</Typography>
					</Grid>
					<Grid item sm={2} sx={{ textAlign: 'right' }}>
						<Typography variant="h6">
							<b>Order: </b>
							{section.order}
						</Typography>
					</Grid>
					<Grid item sm={4} sx={{ textAlign: 'right' }}>
						<Button
							sx={{ mr: 2 }}
							variant="contained"
							onClick={handleEditClick}
						>
							Edit
						</Button>
						<Button
							color="error"
							variant="contained"
							onClick={handleDeleteClick}
						>
							Delete
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<EditSectionDialog
				open={editOpen}
				setOpen={setEditOpen}
				section={section}
			/>
			<DeleteDialog
				open={deleteOpen}
				setOpen={setDeleteOpen}
				message={message}
				collection="sections"
				id={section.id}
				setReset={setSectionsReset}
			/>
		</>
	) : (
		<>
			<Paper sx={{ padding: 2, mt: 1, mb: 1 }}>
				<Grid container spacing={2} justifyContent='center'>
					<Grid item sm={6} sx={{textAlign:'center'}}>
						<Typography variant="h6">{section.label}</Typography>
					</Grid>
					<Grid item sm={2} sx={{ textAlign:'center' }}>
						<Typography variant="h6">
							<b>Order: </b>
							{section.order}
						</Typography>
					</Grid>
					<Grid item xs={10} sx={{ textAlign: 'center' }}>
						<Button
							sx={{ mr: 2 }}
							variant="contained"
							onClick={handleEditClick}
						>
							Edit
						</Button>
						<Button
							color="error"
							variant="contained"
							onClick={handleDeleteClick}
						>
							Delete
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<EditSectionDialog
				open={editOpen}
				setOpen={setEditOpen}
				section={section}
			/>
			<DeleteDialog
				open={deleteOpen}
				setOpen={setDeleteOpen}
				message={message}
				collection="sections"
				id={section.id}
				setReset={setSectionsReset}
			/>
		</>
	);
}

export default Section;
