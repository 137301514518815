import { ListItem, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeroomSelect from './HomeroomSelect';
import NarrativePeriodSelect from './NarrativePeriodSelect';
import { AuthContext } from '../../providers/AuthProvider';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { loggedInStaffAtom, availableHomeroomsAtom } from '../../recoil/atoms';

type Props = {
	handleClose: () => void;
};

export default function MobileLinks({ handleClose }: Props) {
	const { currentAuthUser } = useContext(AuthContext);
	const loggedInStaff = useRecoilValue(loggedInStaffAtom);
	const availableHomerooms = useRecoilValue(availableHomeroomsAtom);
	return (
		<>
			<MenuItem onClick={handleClose}>
				<Link to="/staff" className="navLinkMenu">
					Staff
				</Link>
			</MenuItem>
			<MenuItem onClick={handleClose}>
				<Link to="/settings" className="navLinkMenu">
					Settings
				</Link>
			</MenuItem>
			<MenuItem onClick={handleClose}>
				<Link to="/student" className="navLinkMenu">
					Student Sections
				</Link>
			</MenuItem>
			<MenuItem onClick={handleClose}>
				<Link to="/class" className="navLinkMenu">
					Class Sections
				</Link>
			</MenuItem>
			<MenuItem onClick={handleClose}>
				<Link to="/print" className="navLinkMenu">
					Print
				</Link>
			</MenuItem>
			{/* Change the text color to black and change the bg color back to default */}
			<MenuItem onClick={handleClose} sx={{ width: '9em' }}>
				{loggedInStaff && availableHomerooms && currentAuthUser && (
					<HomeroomSelect />
				)}
			</MenuItem>{' '}
			<MenuItem onClick={handleClose} sx={{ width: '9em' }}>
				<NarrativePeriodSelect />
			</MenuItem>
		</>
	);
}
