import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ImageDeleteConfirmation from "./ImageDeleteConfirmation";
import EditIcon from "@mui/icons-material/Edit";
import ImageCaptionEditDialog from "./ImageCaptionEditDialog";
import { ImageProps, ImageDetails } from "../../interfaces/interfaces";

type Props = {
  image: ImageDetails;
  col: string;
  id: string;
  images: ImageProps[];
  index: number;
  forPrint: boolean;
};

const ImagePreview = ({ image, col, id, images, index, forPrint }: Props) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [ratios, setRatios] = useState({ xs: 12, sm: 6, md: 4 });

  useEffect(() => {
    if (!image) return;
    if (image.columns) {
      setRatios(image.columns);
    }
  }, [image]);

  const handleDeleteConfirmation = () => {
    setDeleteOpen(true);
  };

  const handleEdit = () => {
    setEditOpen(true);
  };
  return (
    <>
      <Grid
        item
        key={image.url}
        sm={ratios.sm}
        xs={ratios.xs}
        md={ratios.md}
        sx={{ mt: 2, position: "relative" }}
      >
        {!forPrint && (
          <>
            <HighlightOffRoundedIcon
              onClick={handleDeleteConfirmation}
              sx={{
                position: "absolute",
                right: 50,
                top: 20,
                cursor: "pointer",
                backgroundColor: "rgba(100,  100,  100,  .5)",
                borderRadius: "20px",
                color: "#fff",
              }}
            />
            <EditIcon
              onClick={handleEdit}
              sx={{
                position: "absolute",
                right: 15,
                top: 20,
                cursor: "pointer",
                backgroundColor: "rgba(100,  100,  100,  .5)",
                borderRadius: "20px",
                color: "#fff",
                fontSize: "20px",
                padding: "2px",
              }}
            />
          </>
        )}
        <img
          src={`${image.url}`}
          alt={image.url}
          width="100%"
          className="raw-image"
        />
        <Box sx={{ maxHeight: "300px", overflow: "scroll" }}>
          <Typography variant="body2">{image.caption}</Typography>
        </Box>
      </Grid>
      {!forPrint && (
        <>
          <ImageDeleteConfirmation
            open={deleteOpen}
            setOpen={setDeleteOpen}
            col={col}
            id={id}
            images={images}
            index={index}
          />
          <ImageCaptionEditDialog
            open={editOpen}
            setOpen={setEditOpen}
            caption={image.caption}
            col={col}
            id={id}
            existingImages={images}
            index={index}
            imageURL={image.url}
          />
        </>
      )}
    </>
  );
};

export default React.memo(ImagePreview);
