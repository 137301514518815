import { Box } from "@mui/material";
import { EntryInterface } from "../../interfaces/interfaces";

import parse from "html-react-parser";

type Props = {
  entry: EntryInterface | undefined;
};

function EntryHtml({ entry }: Props) {
  return (
    <Box sx={{ textAlign: "justify", pt: 2 }}>{entry?.html && parse(entry.html as string)}</Box>
  );
}

export default EntryHtml;
