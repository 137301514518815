import {
  studentsAtom,
  selectedHomeroomIdAtom,
  homeroomsAtom,
  loggedInStaffAtom,
  sectionsAtom,
  rosterOverridesAtom,
  selectedPeriodIdAtom,
  mClassRecordsAtom,
  rawMClassRecordsAtom,
} from "./atoms";
import { GetRecoilValue } from "recoil";
import {
  HomeroomInterface,
  MClassRecordInterface,
  SectionInterface,
  StaffInterface,
  StudentInterface,
} from "../interfaces/interfaces";
import { PERMISSION } from "../libraries/objects";
import { rawStudentCourseScoresAtom, rawStudentStandardScoresAtom } from "./studentScoresAtoms";
import { StudentCourseScoreRecord, StudentStandardScoreRecord } from "../types/types";

type Props = {
  get: GetRecoilValue;
};

export const availableStudentsGetter = ({ get }: Props) => {
  const allStudents = get<StudentInterface[]>(studentsAtom);
  const selectedHomeroomId = get(selectedHomeroomIdAtom);
  const selectedPeriodId = get(selectedPeriodIdAtom);
  const rosterOverrides = get(rosterOverridesAtom);
  if (!allStudents) return [];
  const studentsFilteredByStatus = allStudents.filter(
    (student) => student?.enrollStatus === "Actively Enrolled"
  );
  if (selectedHomeroomId === "all") {
    return studentsFilteredByStatus;
  } else {
    const studentsFilteredByHomeroom = studentsFilteredByStatus.filter((student) => {
      const rosterOverride = rosterOverrides?.find(
        (overrides) => overrides.studentId === student.id && selectedPeriodId === overrides.periodId
      );
      return rosterOverride
        ? selectedHomeroomId === rosterOverride.homeroomId
        : selectedHomeroomId === student.homeroom;
    });
    return studentsFilteredByHomeroom;
  }
};

export const classSectionsGetter = ({ get }: Props) => {
  const sections = get<SectionInterface[] | null>(sectionsAtom);
  return sections?.filter((section) => section.sectionType === "class") ?? null;
};

export const studentSectionsGetter = ({ get }: Props) => {
  const sections = get<SectionInterface[] | null>(sectionsAtom);
  return sections?.filter((section) => section.sectionType === "student") ?? null;
};

export const availableHomeroomsGetter = ({ get }: Props) => {
  
  const homerooms = get<HomeroomInterface[] | null>(homeroomsAtom);
  const loggedInStaff = get<StaffInterface | null>(loggedInStaffAtom);
  if (!homerooms || !loggedInStaff) return null;
  if (!loggedInStaff.permissions?.includes(PERMISSION.SEE_ALL_ROSTERS)) {
    return homerooms.filter((hr) => loggedInStaff.homerooms.includes(hr.id) && hr.active);
  } else {
    return homerooms.filter((hr) => hr.active);
  }
};

export const studentsObjectGetter = ({ get }: { get: GetRecoilValue }) => {
  const students = get(studentsAtom);
  if (!students) return null;
  const tempObj: { [key: string]: StudentInterface } = {};
  students.forEach((student) => {
    tempObj[student.id] = student;
  });
  return tempObj;
};

export const mClassWithSixDigitIds = ({ get }: { get: GetRecoilValue }) => {
  const mClassRecords = get(rawMClassRecordsAtom);
  if (!mClassRecords) return;
  const tempArray: MClassRecordInterface[] = [];
  mClassRecords.forEach((mClassRecord) => {
    const zerosToAdd = 6 - mClassRecord.SID.length;
    let tempSID = mClassRecord.SID;
    for (let i = 0; i < zerosToAdd; i++) {
      tempSID = "0" + tempSID;
    }
    tempArray.push({ ...mClassRecord, SID: tempSID });
  });
  return tempArray;
};

