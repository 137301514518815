import { useContext, useState, useEffect, useCallback } from 'react';
import {
	AppBar,
	Box,
	Toolbar,
	Typography,
	Button,
	IconButton,
	Menu,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { loggedInStaffAtom } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { AuthContext } from '../../providers/AuthProvider';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import MobileLinks from './MobileLinks';
import WebLinks from './WebLinks';
import NarrativePeriodSelect from './NarrativePeriodSelect';
import HomeroomSelect from './HomeroomSelect';
import { availableHomeroomsAtom } from '../../recoil/atoms';

const Navbar = () => {
	const { logout, currentAuthUser } = useContext(AuthContext);
	const loggedInStaff = useRecoilValue(loggedInStaffAtom);
	const [collapse, setCollapse] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const availableHomerooms = useRecoilValue(availableHomeroomsAtom);
	const { width } = useWindowDimensions();
	useEffect(() => {
		if (width > 1000) {
			setCollapse(false);
		}

		if (width <= 1000) {
			setCollapse(true);
		}
	}, [width]);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		},
		[]
	);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="fixed">
				<Toolbar>
					{collapse && (
						<>
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								sx={{ mr: 2 }}
								onClick={handleClick}
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="collapse-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								{loggedInStaff && currentAuthUser && (
									<MobileLinks handleClose={handleClose} />
								)}
							</Menu>
						</>
					)}
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						TGP Narratives
					</Typography>
					{!collapse && loggedInStaff && (
						<WebLinks loggedInStaff={loggedInStaff} />
					)}
					{width > 900 &&
						loggedInStaff &&
						availableHomerooms &&
						currentAuthUser && (
							<>
								<HomeroomSelect />
								<NarrativePeriodSelect />
							</>
						)}
					<Button color="inherit" onClick={logout} sx={{ fontWeight: 700 }}>
						LogOut
					</Button>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default Navbar;
