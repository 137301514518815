import { Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { classSectionsAtom, studentSectionsAtom } from '../../recoil/atoms';
import Section from './Section';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function SectionsList() {
	const classSections = useRecoilValue(classSectionsAtom);
	const studentSections = useRecoilValue(studentSectionsAtom);
  const { width } = useWindowDimensions();

  const mobileTextAlign = (width < 900 ) ? "center" : "left"

	return (
		<>
			<Box>
				<Typography variant="h5" sx={{ mt: 5, mb: 2, textAlign: mobileTextAlign }}>
					Sections for the Class Page
				</Typography>
				{classSections &&
					classSections.map((section) => (
						
							<Section key={section.id} section={section} />
						
					))}
				<Typography variant="h5" sx={{ mt: 5, mb: 2, textAlign: mobileTextAlign}}>
					Sections for the Student Page
				</Typography>
				{studentSections &&
					studentSections.map((section) => (
						<Section key={section.id} section={section} />
					))}
			</Box>
		</>
	);
}

export default SectionsList;
