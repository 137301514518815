import { useRecoilValue } from "recoil";
import {
  selectedStudentIdAtom,
  availableStudentsAtom,
  homeroomsAtom,
  selectedHomeroomIdAtom,
  selectedPeriodIdAtom,
  periodsAtom,
} from "../../recoil/atoms";

function Footer() {
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const students = useRecoilValue(availableStudentsAtom);
  const homerooms = useRecoilValue(homeroomsAtom);
  const periods = useRecoilValue(periodsAtom);

  const currentStudent = students.find((student) => student.id === selectedStudentId);
  const currentHomeroom = homerooms.find((homeroom) => homeroom.id === selectedHomeroomId);
  const currentPeriod = periods.find((period) => period.id === selectedPeriodId);

  const today = new Date().toDateString();

  document.title =`The Gathering Place Narrative for ${currentStudent?.childFirstName} ${currentStudent?.childLastName} in ${currentHomeroom?.name}'s class for the ${currentPeriod?.label} printed on ${today}.`

  return (
    <footer className="page-footer">
      {`The Gathering Place Narrative for ${currentStudent?.childFirstName} ${currentStudent?.childLastName} in ${currentHomeroom?.name}'s class for the ${currentPeriod?.label} printed on ${today}.`}
    </footer>
  );
}

export default Footer;
