import { useEffect, useCallback, useContext, useState } from "react";
import { Paper, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import "quill-paste-smart";
import { useRecoilValue } from "recoil";
import {
  entriesAtom,
  selectedHomeroomIdAtom,
  selectedPeriodIdAtom,
  selectedStudentIdAtom,
} from "../../recoil/atoms";
import { EntryInterface, SectionInterface } from "../../interfaces/interfaces";
import RichTextEditor from "./RichTextEditor";
import { SyncContext } from "../../providers/SyncProvider";
import SetObjectivesContainer from "../SetObjectives/SetObjectivesContainer";
import ImageAccordion from "./ImageAccordion";
import StatusChip from "./StatusChip";

type Props = {
  section: SectionInterface;
  sectionType: string;
};

const RichTextEditorContainer = ({ section, sectionType }: Props) => {
  const entries = useRecoilValue(entriesAtom);
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const selectedHomeroomId = useRecoilValue(selectedHomeroomIdAtom);
  const selectedPeriodId = useRecoilValue(selectedPeriodIdAtom);
  const [text, setText] = useState<ReactQuill.Value>("");
  const [entry, setEntry] = useState<EntryInterface | null>();
  const [hasEdited, setHasEdited] = useState(false);
  const [focused, setFocused] = useState(false);
  const { setPendingEntry, isSyncing } = useContext(SyncContext);

  //TODO: Setup Debounce
  const handleTextChange = useCallback(
    (
      newValue: ReactQuill.Value,
      delta: ReactQuill.ReactQuillProps,
      source: ReactQuill.ReactQuillProps
    ) => {
      if (typeof source === "string" && source === "user") {
        setText(newValue);
        if (entry) {
          //import debounce from lodash
          //wrap setPending in debounce function
          setPendingEntry({ ...entry, html: newValue });
          setHasEdited(true);
        }
      }
    },
    [entry, setPendingEntry]
  );

  useEffect(() => {
    if (!isSyncing) {
      setHasEdited(false);
    }
  }, [isSyncing]);

  const handleOnFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setFocused(false);
  }, []);

  useEffect(() => {
    handleOnBlur();
    if (!entries) return;
    if (sectionType === "class") {
      const currentEntry = entries.find(
        (filteredEntry) =>
          filteredEntry.sectionId === section.id &&
          filteredEntry.studentId === "none" &&
          filteredEntry.homeroomId === selectedHomeroomId &&
          filteredEntry.periodId === selectedPeriodId
      );
      if (currentEntry) {
        setEntry(currentEntry);
        setText(currentEntry.html);
      }
    } else {
      const currentEntry = entries.find(
        (filteredEntry) =>
          filteredEntry.sectionId === section.id &&
          filteredEntry.studentId === selectedStudentId &&
          filteredEntry.periodId === selectedPeriodId
      );
      if (currentEntry) {
        setEntry(currentEntry);
        setText(currentEntry.html);
      }
    }
  }, [
    sectionType,
    entries,
    selectedStudentId,
    section,
    selectedHomeroomId,
    handleOnBlur,
    selectedPeriodId,
  ]);

  return (
    <Paper sx={{ padding: 4, mt: 2 }}>
      <StatusChip focused={focused} hasEdited={hasEdited} isSyncing={isSyncing} section={section} />
      {section.description.length > 0 && (
        <Typography
          sx={{ backgroundColor: "#FFFFDD", padding: "4px", borderRadius: "4px" }}
          variant="body1"
          component="span"
        >
          {section.description}
        </Typography>
      )}
      <RichTextEditor
        text={text}
        handleTextChange={handleTextChange}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
      />
      {entry && sectionType === "student" && (
        <SetObjectivesContainer section={section} entry={entry} />
      )}
      {entry && <ImageAccordion entry={entry} />}
    </Paper>
  );
};

export default RichTextEditorContainer;
