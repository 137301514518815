import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { selectedStudentIdAtom, studentsAtom, studentsObjAtom } from "../../recoil/atoms";
import {
  studentCourseScoresAtom,
  studentStandardScoresAtom,
} from "../../recoil/studentScoresAtoms";
import {
  OverviewDataRecord,
  StudentCourseScoreRecord,
  StudentStandardScoreRecord,
} from "../../types/types";
import AcademicData from "./AcademicData";
import { overviewDataAtom } from "../../recoil/academicAtoms";

function AcademicDataContainer() {
  const studentCourseScores = useRecoilValue(studentCourseScoresAtom);
  const studentStandardScores = useRecoilValue(studentStandardScoresAtom);
  const overviewData = useRecoilValue(overviewDataAtom);
  const students = useRecoilValue(studentsAtom);
  const [selectedStudentOverviews, setSelectedStudentOverviews] = useState<OverviewDataRecord[]>(
    []
  );
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);

  const removeZeros = (sid: string) => sid.replace(/^0+/, "");

  useEffect(() => {
    if (!selectedStudentId || !overviewData) return;
    const selectedStudent = students.find((student) => student.id === selectedStudentId);
    if (!selectedStudent) return;
    const filteredOverviews = overviewData.filter(
      (student) => student.sid === removeZeros(selectedStudent.SID)
    );
    setSelectedStudentOverviews(filteredOverviews);
  }, [overviewData, selectedStudentId, students]);

  return <>{/* <AcademicData selectedStudentOverviews={selectedStudentOverviews} /> */}</>;
}

export default AcademicDataContainer;
